import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'SITE_UPDATE',
    smsDelete : 'SITE_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {
        
        site_detail:{},

        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        siteLoading: false,
        siteError: false,
  

        ...store
    },

    mutations : {
        ...mutations,
       
    

        SET_SITE_DETAIL(state, detail) {
            state.site_detail = detail;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },
        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },


        SITE_LOADING (state, loading) {
            state.siteLoading = loading;
        },

        SITE_ERROR (state, hasError) {
            state.siteError = hasError;
        },
        
       
       
    },

    actions: {
        //Base Detail
        async addSiteDetail ({commit, dispatch}, payload) {
            commit('SITE_LOADING', true);
            commit('SITE_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_site_setting, payload.site_detail,  config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
               
                return response

            }catch (e) {
                commit('SITE_ERROR', true);
            }finally {
                commit('SITE_LOADING', false);
            }
        },

        async fetchSiteDetail ({ commit },payload) {
            commit('SITE_LOADING', true);
            commit('SITE_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_site_setting, config);
                let response = result.data.data

                commit('SET_SITE_DETAIL', response);

            } catch(error) {
                commit('SITE_ERROR', true);
            }
            finally {
                commit('SITE_LOADING', false);
            }
        },

        
        // async deleteCampaign({commit, dispatch}, payload) {
        //     let config = {
        //         headers: {
        //           Authorization: "Bearer " + localStorage.getItem("access_token"),
        //         }
        //     };

        //     let response = await axios.delete(V1API.campaign_delete + payload.delete, config).then(result=>{
        //         let data = result.data
        //         return data
        //     })
            
        //     dispatch ('fetchCampaign')
            
        //     return response
        // },


        






       








    },

    getters: {}
}
