import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'CONTACT_UPDATE',
    smsDelete : 'CONTACT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {
        
        contacts:[],
       
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        contactLoading: false,
        contactError: false,

        subscribers:[],
        subscribersLoading: false,
        subscribersError: false,

        ...store
    },

    mutations : {
        ...mutations,
       

        SET_CONTACTS(state, contacts) {
            state.contacts = contacts;
        },

       
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },
        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },
        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        },

        CONTACT_LOADING (state, loading) {
            state.contactLoading = loading;
        },

        CONTACT_ERROR (state, hasError) {
            state.contactError = hasError;
        },



        SET_SUBSCRIBERS(state, subscribers) {
            state.subscribers = subscribers;
        },

        SUBSCRIBERS_LOADING (state, loading) {
            state.subscribersLoading = loading;
        },

        SUBSCRIBERS_ERROR (state, hasError) {
            state.subscribersError = hasError;
        },

        
      
    },

    actions: {
        //SubscribersList
        async fetchSubscribers ({ commit }) {
            commit('SUBSCRIBERS_LOADING', true);
            commit('SUBSCRIBERS_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_subscribers_list, config);
                let response = result.data

                commit('SET_SUBSCRIBERS', response.data);

            } catch(error) {
                commit('SUBSCRIBERS_ERROR', true);
            }
            finally {
                commit('SUBSCRIBERS_LOADING', false);
            }
        },
        
        //Contact
        async fetchContact ({ commit }) {
            commit('CONTACT_LOADING', true);
            commit('CONTACT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.all_message, config);
                let response = result.data

                commit('SET_CONTACTS', response.data);

            } catch(error) {
                commit('CONTACT_ERROR', true);
            }
            finally {
                commit('CONTACT_LOADING', false);
            }
        },



        
        async deleteContact({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.message_delete + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchContact')
            
            return response
        },



    },

    getters: {}
}
