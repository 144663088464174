<template>
    <div>
        <Breadcrumbs main="Admin" submain="Add" title="Application Form" />

        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;">
                                <div style="display: flex; align-items: center;">
                                    <h5>Application Details</h5>
                                </div>
                                <div>
                                    <router-link :to="'/admin/application/edit/' + this.$route.params.id" style="display: flex; gap: 12px; align-items: center; color: white;">
                                    <button class="btn btn-primary" type="submit">
                                      
                                            <i class="fa fa-light fa-pencil mr-4"></i> 
                                            <h7>Edit Application</h7>
                                       
                                    </button>
                                </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div slot="with-padding">
                                        <div class="form theme-form">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="form-group">
                                                        <h6>Title</h6>
                                                        <h7>{{ application.title }}</h7>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="form-group">
                                                        <h6>Details</h6> 
                                                        <div v-html="application.details"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <div>
                                <h5>Generate Form</h5>
                            </div>
                        </div>

                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <div style="display: flex; flex-direction: column;">
                                            <div style="width: 50%; display: flex; flex-direction: column;">
                                                <h6>Form Name</h6>
                                                <b-form-input v-model="form_data.form_name" type="text" placeholder="Enter form name"></b-form-input>
                                            </div>

                                            <div class="mt-4" style="display: flex; gap: 16px;">
                                                <div style="width: 45%;">
                                                    <h6>Label</h6>
                                                </div>
                                    
                                                <div style="width: 45%;">
                                                    <h6>Type</h6>
                                                </div>
                                    
                                                <div style="width: 10%;">
                                                    <h6>Action</h6>
                                                </div>
                                            </div>
                                
                                            <div style="display: flex; gap: 16px; margin-bottom: 12px;" v-for="(fo, index) in form_data.submission_form" :key="index">
                                                <div style="width: 45%;">
                                                    <div class="w-fullbg-white rounded-sm">
                                                        <b-form-input v-model="fo.data_label" type="text" placeholder="Enter label"></b-form-input>
                                                    </div>
                                                </div>
                                    
                                                <div style="width: 45%;">
                                                    <div>
                                                        <b-form-select v-model="fo.data_type">
                                                            <b-form-select-option v-for="option in options" :value="option.value">{{ option.title }}</b-form-select-option>
                                                        </b-form-select>
                                                    </div>
                                                </div>
                                    
                                                <div style="width: 10%; display: flex; align-items: center;">
                                                    <!-- Add Svg Icon -->
                                                    <svg @click="addField(input, form_data.submission_form)" width="24" height="24"
                                                        class="ml-2 cursor-pointer" style="cursor: pointer; margin-right: 4px;">
                                                        <path fill="none" d="M0 0h24v24H0z"/>
                                                        <path fill="green"
                                                            d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"/>
                                                    </svg>
                                    
                                                    <!-- Remove Svg Icon -->
                                                    <svg v-show="form_data.submission_form.length > 1" @click="removeField(index, form_data.submission_form)"
                                                        width="24" height="24" style="cursor: pointer; margin-left: 4px;">
                                                        <path fill="none" d="M0 0h24v24H0z"/>
                                                        <path fill="#B13032"
                                                            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"/>
                                                    </svg>
                                                </div>
                                            </div>

                                            <div class="row mt-2">
                                                <div class="col">
                                                    <div class="form-group mb-0">
                                                        <button @click="onSubmit" class="btn btn-success mr-3">Update</button>
                                                        <router-link to="/admin/application">
                                                            <button class="btn btn-danger" href="#">Go Back</button>
                                                        </router-link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker
    },

    data(){
        return {
            application: {
                title: '',
                details: ''
            },

            form_data: {
                application: null,
                form_name: "",
        
                submission_form: [
                    {
                        data_label: null,
                        data_type: null
                    }
                ]
            },

            options: [
                {
                    title: 'Text Field',
                    value: 'textfield'
                },
                {
                    title: 'File Field',
                    value: 'filefield'
                },
                {
                    title: 'Link Field',
                    value: 'textfield'
                },
                {
                    title: 'Paragraph',
                    value: 'paragraph'
                }
            ],
        }
    },

    computed: {
        ...mapState({
            detail: state => state.application.application_detail,
            form: state => state.application.form
        })
    },

    mounted() {
        this.$store.dispatch("application/fetchApplicationDetails", this.$route.params.id)
        this.$store.dispatch("application/fetchForm", this.$route.params.id)
    },

    watch: {
        detail(newValue, oldValue) {
            this.application.title = this.detail.title
            this.application.details = this.detail.details
        },

        form(newValue, oldValue) {
            if(this.form !== null) {
                this.form_data.application = this.$route.params.id
                this.form_data.form_name = this.form.form_name
                this.form_data.submission_form = this.form.submission_form
            }
        }
    },

    methods: {
        addField(value, fieldType) {
            fieldType.push({value: ""});
        },
    
        removeField(index, fieldType) {
            fieldType.splice(index, 1);
        },

        onSubmit() {
            this.form_data.application = this.$route.params.id

            this.$store.dispatch("application/addForm", this.form_data).then(response => {
                if(response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    setTimeout(() => {
                        this.$router.push('/admin/application')
                    }, 2000)
                    
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
