<template>
<div>
    <Breadcrumbs main="Admin" title="QnA" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-header">
                        <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                            <i class="fa fa-light fa-plus"></i>
                            <h7>Add New QnA</h7>
                        </button>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">

                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="qna_list" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>
                                    <template #cell(type)="row">
                                        <div v-if="row.item.type" style="width: 120px">
                                            <p v-if="row.item.type === 'law_n_policy'">Law & Policy</p>
                                            <p v-if="row.item.type === 'faq'">FAQ</p>
                                        </div>
                                    </template>

                                    <template #cell(question)="row">
                                        <div v-if="row.item.question" style="width: 200px">
                                            <p>{{ truncateText(row.item.question,80) }}</p>
                                        </div>

                                    </template>
                                    <template #cell(answer)="row" >
                                        <div v-if="row.item.answer"  style="width: 400px">
                                            <p>{{ truncateText(row.item.answer,150) }}</p>
                                        </div>
                                    </template>
                                    <template #cell(open)="row">
                                        <div>
                                            <span v-if="row.item.open === true" style="cursor: pointer;">
                                                <b-badge pill variant="success" v-b-tooltip.hover title="Toggle visiblity" @click="ToggleVisiblity(row.item.id)">Yes</b-badge>
                                            </span>
                                            <span v-if="row.item.open === false" style="cursor: pointer;">
                                                <b-badge pill variant="danger" v-b-tooltip.hover title="Toggle visiblity" @click="ToggleVisiblity(row.item.id)">No</b-badge>
                                            </span>
                                        </div>
                                        
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <div>
                                                <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteQNA(data.item.id)" title="Delete"></feather>
                                            </div>

                                            <div @click="editQNA(data.item)">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                            </div>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>

                        <div v-else>
                            <loader/>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- QNA Create Modal -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Add QnA" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit()">
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Type</label>
                        <select class="form-control digits" v-model="qna.type">
                            <option v-for="(type, index) in qnaType" :key="index" :value="type.value">{{ type.name }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Question</label>
                        <input class="form-control" type="text" placeholder="Enter question" v-model="qna.question">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Answer</label>
                        <textarea class="form-control" id="textarea-auto-height" placeholder="Enter answer..." rows="5" max-rows="10" v-model="qna.answer"></textarea>
                    </div>
                </div>
            </div>

        </form>
    </b-modal>

    <!-- Gallery Edit Modal -->
    <b-modal id="modal-prevent-closing-edit" ref="modal" title="Edit Gallery" @show="resetEditModal" @hidden="resetEditModal" @ok="handleEditOk">
        <form ref="form" @submit.stop.prevent="handleEditSubmit">
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Type</label>
                        <select class="form-control digits" v-model="edit_qna.type">
                            <option v-for="(type, index) in qnaType" :key="index" :value="type.value">{{ type.name }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Question</label>
                        <input class="form-control" type="text" placeholder="Enter question" v-model="edit_qna.question">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Answer</label>
                        <textarea class="form-control" id="textarea-auto-height" placeholder="Enter answer..." rows="5" max-rows="10" v-model="edit_qna.answer"></textarea>
                    </div>
                </div>
            </div>

        </form>
    </b-modal>
    <!-- Gallery Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this QnA?</div>
    </b-modal>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import moment from "moment";
import {
    mapState
} from 'vuex';
import {
    myHost
} from '../../../_helpers/host'
import js from '../../../auth/js';
import loader from '../loader/loader.vue'
export default {
    components: {
        Datepicker,
        loader,
    },

    data() {
        return {
            host: myHost.host,
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'type',
                    label: 'Type',
                    sortable: true
                },
                {
                    key: 'question',
                    label: 'Question',
                    sortable: true
                },
                {
                    key: 'answer',
                    label: 'Answer',
                    sortable: true
                },
                {
                    key: 'open',
                    label: 'Visiblity',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],
            qnaType: [{
                    name: 'Law & Policy',
                    value: 'law_n_policy'
                },
                {
                    name: 'FAQ',
                    value: 'faq'
                },
            ],

            qna: {
                type: '',
                question: '',
                answer: '',

            },

            edit_qna: {
                id: '',
                type: '',
                question: '',
                answer: '',

            },

            delete_value: null,

            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15],

        };
    },

    computed: {
        ...mapState({
            qna_list: state => state.qna.qna,
            loading: state => state.qna.qnaLoading,
            error: state => state.qna.qnaError,

        }),

        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        },

    },

    mounted() {
        this.$store.dispatch("qna/fetchQNA").then(() => {
            this.items = this.qna_list
            this.totalRows = this.items.length;
        });

    },

    methods: {
        ToggleVisiblity(type){
            this.$store.dispatch("qna/toggleQNAVisiblity", {
                id: type
            }).then(response => {

                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                  

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

           
        },

        truncateText(text, maxLength) {
            if (text.length > maxLength) {
                return text.substring(0, maxLength) + '...';
            } else {
                return text;
            }
        },
        uploadFile(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.show_image = e.target.result
                this.gallery.image = e.target.result;
                this.edit_gallery.image = e.target.result;
            };
        },
        EdituploadFile(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.show_image = e.target.result
                this.edit_gallery.image = e.target.result;
            };
        },
        onFiltered(filteredItems) {

            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        resetModal() {
            this.department.name = ''
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()

        },

        handleSubmit() {

            this.$store.dispatch("qna/addQNA", {
                qna: this.qna
            }).then(response => {

                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.qna.type = null,
                        this.qna.question = null
                    this.qna.answer = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        editQNA(detail) {

            this.edit_qna.id = detail.id ? detail.id : null
            this.edit_qna.type = detail.type ? detail.type : null
            this.edit_qna.question = detail.question ? detail.question : null
            this.edit_qna.answer = detail.answer ? detail.answer : nullanswer

        },

        resetEditModal() {
            this.edit_department.name = ''

        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },

        handleEditSubmit() {

            this.$store.dispatch("qna/updateQNA", {
                id: this.edit_qna.id,
                qna: this.edit_qna,

            }).then(response => {

                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.edit_qna.id = ''
                    this.edit_qna.type = ''
                    this.edit_qna.question = ''
                    this.edit_qna.answer = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit')
            })
        },
        deleteQNA(id) {
            this.delete_value = id
        },
        handleDeleteOk() {
            this.$store.dispatch("qna/deleteQNA", {
                delete: this.delete_value
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
