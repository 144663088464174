import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'PARTNER_UPDATE',
    smsDelete : 'PARTNER_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {
        
        partner:[],
      
        partner_detail:{},

        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        partnerLoading: false,
        partnerError: false,

        
       
        ...store
    },

    mutations : {
        ...mutations,

        SET_PARTNER(state, partner) {
            state.partner = partner;
        },

        SET_PARTNER_DETAIL(state, detail) {
            state.partner_detail = detail;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },
        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },


        PARTNER_LOADING (state, loading) {
            state.partnerLoading = loading;
        },

        PARTNER_ERROR (state, hasError) {
            state.partnerError = hasError;
        },
        


       
    },

    actions: {
        //Partner
        async addPartner ({commit, dispatch}, payload) {
            commit('PARTNER_LOADING', true);
            commit('PARTNER_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_partner, payload.partner, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchPartner')
               
                return response

            }catch (e) {
                commit('PARTNER_ERROR', true);
            }finally {
                commit('PARTNER_LOADING', false);
            }
        },

        async fetchPartnerDetail ({ commit },payload) {
            commit('PARTNER_LOADING', true);
            commit('PARTNER_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_partner_details + payload, config);
                let response = result.data.data

                commit('SET_PARTNER_DETAIL', response);

            } catch(error) {
                commit('PARTNER_ERROR', true);
            }
            finally {
                commit('PARTNER_LOADING', false);
            }
        },



        async fetchPartner ({ commit }) {
            commit('PARTNER_LOADING', true);
            commit('PARTNER_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.partner_list, config);
                let response = result.data

                commit('SET_PARTNER', response.data);

            } catch(error) {
                commit('PARTNER_ERROR', true);
            }
            finally {
                commit('PARTNER_LOADING', false);
            }
        },


    
        async updatePartner ({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.patch(V1API.update_partner+payload.id, payload.partner, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchPartner')
                return response

            }catch (e) {
                commit('PARTNER_ERROR', true);
            }finally {
                commit('PARTNER_LOADING', false);
            }
        },

        
        async deletePartner({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.partner_delete + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchPartner')
            
            return response
        },




    },

    getters: {}
}
