<template>
<div>
    <div class="d-flex flex-column justify-content-center align-items-center">
        <!-- <img src="../../../assets/images/logo/logo.svg" alt="" style="width: 240px; height: 80px;"> -->
        <div class="loader-box">
            <div class="loader-17"></div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {

        };
    },

};
</script>

<style scoped>

</style>
