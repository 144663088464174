import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'GALLERY_UPDATE',
    smsDelete : 'GALLERY_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {
        
        galleries:[],
      
        gallery_detail:{},

        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        galleryLoading: false,
        galleryError: false,

        
       
        ...store
    },

    mutations : {
        ...mutations,

        SET_GALLERY(state, law) {
            state.galleries = law;
        },

        SET_GALLERY_DETAIL(state, detail) {
            state.gallery_detail = detail;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },
        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },


        GALLERY_LOADING (state, loading) {
            state.galleryLoading = loading;
        },

        GALLERY_ERROR (state, hasError) {
            state.galleryError = hasError;
        },
        


       
    },

    actions: {
        //Gallery
        async addGallery ({commit, dispatch}, payload) {
            commit('GALLERY_LOADING', true);
            commit('GALLERY_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_gallery, payload.gallery, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchGallery')
               
                return response

            }catch (e) {
                commit('GALLERY_ERROR', true);
            }finally {
                commit('GALLERY_LOADING', false);
            }
        },

        async fetchGalleryDetail ({ commit },payload) {
            commit('GALLERY_LOADING', true);
            commit('GALLERY_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_gallery_details + payload, config);
                let response = result.data.data

                commit('SET_GALLERY_DETAIL', response);

            } catch(error) {
                commit('GALLERY_ERROR', true);
            }
            finally {
                commit('GALLERY_LOADING', false);
            }
        },



        async fetchGallery ({ commit }) {
            commit('GALLERY_LOADING', true);
            commit('GALLERY_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.gallery_list, config);
                let response = result.data

                commit('SET_GALLERY', response.data);

            } catch(error) {
                commit('GALLERY_ERROR', true);
            }
            finally {
                commit('GALLERY_LOADING', false);
            }
        },


    
        async updateGallery ({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.patch(V1API.update_gallery+payload.id, payload.gallery, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchGallery')
                return response

            }catch (e) {
                commit('GALLERY_ERROR', true);
            }finally {
                commit('GALLERY_LOADING', false);
            }
        },

        
        async deleteGallery({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.gallery_delete + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchGallery')
            
            return response
        },


        











       








    },

    getters: {}
}
