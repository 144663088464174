import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'REQUISITION_UPDATE',
    smsDelete : 'REQUISITION_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {
        
        news:[],
        newsCategory:[],
        news_detail:{},

        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        newsLoading: false,
        newsrror: false,

        

        newsCategoryLoading: false,
        newsCategoryError: false,

       

        ...store
    },

    mutations : {
        ...mutations,
       
        SET_NEWS_CATEGORY(state, category) {
            state.newsCategory = category;
        },
        SET_NEWS(state, news) {
            state.news = news;
        },

        SET_NEWS_DETAIL(state, detail) {
            state.news_detail = detail;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },


        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },


        NEWS_LOADING (state, loading) {
            state.newsLoading = loading;
        },

        NEWS_ERROR (state, hasError) {
            state.newsError = hasError;
        },
        
        NEWS_CATEGORY_LOADING (state, loading) {
            state.newsCategoryLoading = loading;
        },

        NEWS_CATEGORY_ERROR (state, hasError) {
            state.newsCategoryError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        //News
        async addNews ({commit, dispatch}, payload) {
            commit('NEWS_LOADING', true);
            commit('NEWS_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_news, payload.news, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
               
                return response

            }catch (e) {
                commit('NEWS_ERROR', true);
            }finally {
                commit('NEWS_LOADING', false);
            }
        },

        async fetchNewsDetail ({ commit },payload) {
            commit('NEWS_LOADING', true);
            commit('NEWS_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_news_details + payload, config);
                let response = result.data.data

                commit('SET_NEWS_DETAIL', response);

            } catch(error) {
                commit('NEWS_ERROR', true);
            }
            finally {
                commit('NEWS_LOADING', false);
            }
        },



        async fetchNews ({ commit }) {
            commit('NEWS_LOADING', true);
            commit('NEWS_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.news_list, config);
                let response = result.data

                commit('SET_NEWS', response.data);

            } catch(error) {
                commit('NEWS_ERROR', true);
            }
            finally {
                commit('NEWS_LOADING', false);
            }
        },


    
        async updateNews ({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.patch(V1API.update_news+payload.id, payload.news, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
               
                return response

            }catch (e) {
                commit('NEWS_ERROR', true);
            }finally {
                commit('NEWS_LOADING', false);
            }
        },

        
        async deleteNews({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.news_delete + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchNews')
            
            return response
        },


        

        // News Category
        async addNewsCategory ({commit, dispatch}, payload) {
            commit('NEWS_CATEGORY_LOADING', true);
            commit('NEWS_CATEGORY_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_news_category, payload, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchNewsCategory')
                return response

            }catch (e) {
                commit('NEWS_CATEGORY_ERROR', true);
            }finally {
                commit('NEWS_CATEGORY_LOADING', false);
            }
        },


        async fetchNewsCategory ({ commit }) {
            commit('NEWS_CATEGORY_LOADING', true);
            commit('NEWS_CATEGORY_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.news_category_list, config);
                let response = result.data

                commit('SET_NEWS_CATEGORY', response.data);

            } catch(error) {
                commit('NEWS_CATEGORY_ERROR', true);
            }
            finally {
                commit('NEWS_CATEGORY_LOADING', false);
            }
           
        },


    
        async updateNewsCategory ({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try {

                let response = await axios.patch(V1API.update_news_category+payload.id,{title:payload.title},config).then(result=>{
                    let data = result.data
                    return data

                });
                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchNewsCategory')
                return response

            }catch (e) {
                commit('NEWS_CATEGORY_ERROR', true);
            }finally {
                commit('NEWS_CATEGORY_LOADING', false);
            }
        },

        
        async deleteNewsCategory({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_news_category + payload, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchNewsCategory')
            
            return response
        },


        async newsCustomToggle ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.get(V1API.news_custom_toggle + payload.news_slug, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchNews');
            
            return response
        },








       








    },

    getters: {}
}
