import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'COUNTER_UPDATE',
    smsDelete : 'COUNTER_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {
        
        counters:[],
      


        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        counterLoading: false,
        counterError: false,

        
       
        ...store
    },

    mutations : {
        ...mutations,

        SET_COUNTERS(state, law) {
            state.counters = law;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },
        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },


        COUNTER_LOADING (state, loading) {
            state.counterLoading = loading;
        },

        COUNTER_ERROR (state, hasError) {
            state.counterError = hasError;
        },
        


       
    },

    actions: {
        //Counter
        async addCounter ({commit, dispatch}, payload) {
            commit('COUNTER_LOADING', true);
            commit('COUNTER_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.add_counter, payload.counter, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchCounter')
               
                return response

            }catch (e) {
                commit('COUNTER_ERROR', true);
            }finally {
                commit('COUNTER_LOADING', false);
            }
        },

        async UpdateCounter ({commit, dispatch}, payload) {
            commit('COUNTER_LOADING', true);
            commit('COUNTER_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.patch(V1API.update_counter + payload.counter_id, payload.counter, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchCounter')
               
                return response

            }catch (e) {
                commit('COUNTER_ERROR', true);
            }finally {
                commit('COUNTER_LOADING', false);
            }
        },


        async fetchCounter({ commit }) {
            commit('COUNTER_LOADING', true);
            commit('COUNTER_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.counter_list, config);
                let response = result.data

                commit('SET_COUNTERS', response.data);

            } catch(error) {
                commit('COUNTER_ERROR', true);
            }
            finally {
                commit('COUNTER_LOADING', false);
            }
        },


    
        async deleteCounter({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.counter_delete + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchCounter')
            
            return response
        },

    },

    getters: {}
}
