import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'CONTENT_UPDATE',
    smsDelete : 'CONTENT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {
        
        contents:[],
        content_by_type_slug:[],
        content_by_type:[],
        content_detail:{},

        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        contentLoading: false,
        contentError: false,

        
       
        ...store
    },

    mutations : {
        ...mutations,

        SET_CONTENT(state, content) {
            state.contents = content;
        },
        SET_CONTENT_BY_TYPE_SLUG(state, content) {
            state.content_by_type_slug = content;
        },
        SET_CONTENT_BY_TYPE(state, content) {
            state.content_by_type = content;
        },


        SET_CONTENT_DETAIL(state, detail) {
            state.content_detail = detail;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },
        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },


        CONTENT_LOADING (state, loading) {
            state.contentLoading = loading;
        },

        CONTENT_ERROR (state, hasError) {
            state.contentError = hasError;
        },
        


       
    },

    actions: {
        //Content
        async addContent ({commit, dispatch}, payload) {
            commit('CONTENT_LOADING', true);
            commit('CONTENT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_content, payload.content, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchContent')
                if(payload.custom){
                    dispatch ('fetchContentbyTypeSlug',{type:payload.type, slug:payload.slug})
                }
                return response

            }catch (e) {
                commit('CONTENT_ERROR', true);
            }finally {
                commit('CONTENT_LOADING', false);
            }
        },

        async fetchContentDetail ({ commit },payload) {
            commit('CONTENT_LOADING', true);
            commit('CONTENT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_content_details + payload, config);
                let response = result.data.data

                commit('SET_CONTENT_DETAIL', response);

            } catch(error) {
                commit('CONTENT_ERROR', true);
            }
            finally {
                commit('CONTENT_LOADING', false);
            }
        },



        async fetchContent ({ commit }) {
            commit('CONTENT_LOADING', true);
            commit('CONTENT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.content_list, config);
                let response = result.data

                commit('SET_CONTENT', response.data);

            } catch(error) {
                commit('CONTENT_ERROR', true);
            }
            finally {
                commit('CONTENT_LOADING', false);
            }
        },


        
        async fetchContentbyTypeSlug ({ commit },payload) {
            commit('CONTENT_LOADING', true);
            commit('CONTENT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_content_by_type_slug + payload.type +'/'+payload.slug, config);
                let response = result.data

                commit('SET_CONTENT_BY_TYPE_SLUG', response.data);

            } catch(error) {
                commit('CONTENT_ERROR', true);
            }
            finally {
                commit('CONTENT_LOADING', false);
            }
        },

        async fetchContentbyType ({ commit },payload) {
            commit('CONTENT_LOADING', true);
            commit('CONTENT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_content_by_type + payload.type, config);
                let response = result.data

                commit('SET_CONTENT_BY_TYPE', response.data);

            } catch(error) {
                commit('CONTENT_ERROR', true);
            }
            finally {
                commit('CONTENT_LOADING', false);
            }
        },


    
        async updateContent ({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.patch(V1API.update_content+payload.id, payload.content, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchContent')
                return response

            }catch (e) {
                commit('CONTENT_ERROR', true);
            }finally {
                commit('CONTENT_LOADING', false);
            }
        },

        
        async deleteContent({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.content_delete + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchContent')
            
            return response
        },

    },

    getters: {}
}
