<template>
<div>
    <Breadcrumbs main="Admin" submain="Contact" title="Messages" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <!-- <div class="card-header header-mobile">
                            <router-link to="/admin/quotes-create">
                                <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                                    <i class="fa fa-light fa-plus"></i>
                                    <h7>Add New Quotes</h7>
                                </button>
                            </router-link>

                        </div> -->

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">
                                <div class="d-flex flex-column flex-xl-row " style="gap: 10px;">
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                </div>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="contact_list" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(name)="row">
                                        <div v-if="row.item.name">
                                            {{ row.item.name }}
                                        </div>
                                    </template>
                                    <template #cell(email)="row">
                                        <div v-if="row.item.email">
                                            {{ row.item.email }}
                                        </div>
                                    </template>
                                    <template #cell(phone)="row">
                                        <div v-if="row.item.phone">
                                            {{ row.item.phone }}
                                        </div>
                                    </template>
                                    <template #cell(subject)="row">
                                        <div v-if="row.item.subject" style="min-width: 150px;" >
                                            {{ row.item.subject }}
                                        </div>
                                    </template>

                                    <template #cell(message)="row">
                                        <div v-if="row.item.message" style="min-width: 250px;" >
                                            <p v-b-tooltip.hover title="Details" v-b-modal.modal-prevent-closing-message @click="messageDetails(row.item)">{{ truncateText(row.item.message,100) }}</p>
                                        </div>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="eye" size="20px" style="color: #568ae3; cursor: pointer;" v-b-modal.modal-prevent-closing-message v-b-tooltip.hover title="Details" @click="messageDetails(data.item)"></feather>
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteContacts(data.item.id)" title="Delete"></feather>

                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>

                        <div v-else>
                            <loader />
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this Message?</div>
    </b-modal>

    <!-- Message Modal -->
    <b-modal id="modal-prevent-closing-message" ref="modal" title="Message Details" hide-footer>
        <form ref="form">

            <div class="row">
                <div class="col-sm-12">
                    <b-form-group label="Name" label-for="name-input">
                        <b-form-input v-model="message_details.name" placeholder="" disabled></b-form-input>
                    </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <b-form-group label="Email" label-for="name-input">
                        <b-form-input v-model="message_details.email" placeholder="" disabled></b-form-input>
                    </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <b-form-group label="Subject" label-for="name-input">
                        <b-form-input v-model="message_details.subject" placeholder="" disabled></b-form-input>
                    </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <b-form-group label="Phone" label-for="name-input">
                        <b-form-input v-model="message_details.phone" placeholder="" disabled></b-form-input>
                    </b-form-group>
                </div>
            </div>
     
            <b-form-group label="Message" label-for="name-input">
                <b-form-textarea id="textarea-rows" placeholder="" rows="8" v-model="message_details.message" disabled></b-form-textarea>
            </b-form-group>

        </form>
    </b-modal>

</div>
</template>

    
<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import {
    myHost
} from '../../../_helpers/host'
import loader from '../loader/loader.vue'
export default {
    components: {
        loader,
    },
    data() {
        return {
            host: myHost.host,
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'email',
                    label: 'Email',
                    sortable: true
                },
                {
                    key: 'phone',
                    label: 'Phone',
                    sortable: true
                },
                {
                    key: 'subject',
                    label: 'Subject',
                    sortable: true
                },

                {
                    key: 'message',
                    label: 'Message',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],
            message_details:{
                name:'',
                email:'',
                phone:'',
                message:'',
                subject:'',
            },

            filter: null,
            delete_value: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15]
        };
    },

    computed: {
        ...mapState({
            contact_list: state => state.contact.contacts,
            loading: state => state.contact.contactLoading,
            error: state => state.contact.contactError,
        }),

        sortOptions() {
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        }
    },

    mounted() {

        this.$store.dispatch("contact/fetchContact").then(() => {
            this.items = this.contact_list
            this.totalRows = this.items.length;
           

        });

    },

    watch: {

    },

    methods: {
        messageDetails(type){
            this.message_details.name = type.name ? type.name :null
            this.message_details.email = type.email ? type.email :null
            this.message_details.phone = type.phone ? type.phone :null
            this.message_details.message = type.message ? type.message :null
            this.message_details.subject = type.subject? type.subject : null
            
        },
        truncateText(text, maxLength) {
            if (text.length > maxLength) {
                return text.substring(0, maxLength) + '...';
            } else {
                return text;
            }
        },

        resetModal() {
            this.selectedStatus = ''
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        deleteContacts(id) {
            this.delete_value = id
        },

        handleDeleteOk() {
            this.$store.dispatch("contact/deleteContact", {
                delete: this.delete_value,
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

    }
};
</script>

    
<style scoped>
.header-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 460px) {
    .header-mobile {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: start;
    }
}
</style>
