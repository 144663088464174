<template>
<div>
    <Breadcrumbs main="Admin" submain="Press Release" title="Quotes Create" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">

                            <div class="row">
                                <div class="col-sm-8">
                                    <div class="form-group">
                                        <label>Press Release</label>
                                        <select class="form-control digits" v-model="details.press_release">
                                            <option v-for="(item, index) in press_list" :key="index" :value="item.id">{{ item.title }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <input class="form-control" type="text" placeholder="Enter name" v-model="details.name">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Desigantion</label>
                                        <input class="form-control" type="text" placeholder="Enter designation" v-model="details.designation">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label>Short Description</label>
                                        <textarea class="form-control" id="textarea-auto-height" placeholder="Enter short description..." rows="2" max-rows="2" v-model="details.short_desc"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label>Image (Ratio 1:1)</label>
                                        <div class="flex items-center">
                                            <img v-if="show_image" style="height: 80px;" :src="show_image">
                                            <input :class="details.image ? 'ml-4' : 'ml-0'" type="file" accept="image/*" @change="uploadFile">
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button @click="onSubmit" class="btn btn-success mr-3" :disabled="isDisabled">Create</button>
                                        <router-link to="/admin/quotes">
                                            <button class="btn btn-danger" href="#">Go Back</button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </px-card>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from "moment";
import Multiselect from 'vue-multiselect';
import 'sweetalert2/src/sweetalert2.scss';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        Multiselect
    },

    data() {
        return {
            isDisabled: false,
            editor: ClassicEditor,
            details: {
                press_release: null,
                name: null,
                designation: null,
                short_desc: null,
                image: null,
            },
            show_image: null,

        };
    },

    computed: {
        ...mapState({
            press_list: state => state.press.pressReleases,
        }),

    },

    mounted() {
        this.$store.dispatch("press/fetchPress")
    },

    methods: {

        uploadFile(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.show_image = e.target.result
                this.details.image = e.target.result;
            };
        },

        onSubmit() {
            this.isDisabled = true
            this.$store.dispatch("press/addQuotes", {
                quotes: this.details,
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                        this.isDisabled = false,
                        this.details.press_release = null,
                        this.details.name = null,
                        this.details.designation = null,
                        this.details.short_desc = null,
                        this.details.image = null,

                        setTimeout(() => {
                            this.$router.push('/admin/quotes')
                        }, 2000)

                } else {
                    this.isDisabled = false
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }

            })
        }
    }
};
</script>
