import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'REQUISITION_UPDATE',
    smsDelete : 'REQUISITION_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {
        
        laws:[],
        lawCategory:[],
        law_detail:{},

        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        lawLoading: false,
        lawError: false,

        

        lawCategoryLoading: false,
        lawCategoryError: false,

       

        ...store
    },

    mutations : {
        ...mutations,
       
        SET_LAW_CATEGORY(state, category) {
            state.lawCategory = category;
        },
        SET_LAW(state, law) {
            state.laws = law;
        },

        SET_LAW_DETAIL(state, detail) {
            state.law_detail = detail;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },


        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },


        LAW_LOADING (state, loading) {
            state.lawLoading = loading;
        },

        LAW_ERROR (state, hasError) {
            state.lawError = hasError;
        },
        
        LAW_CATEGORY_LOADING (state, loading) {
            state.lawCategoryLoading = loading;
        },

        LAW_CATEGORY_ERROR (state, hasError) {
            state.lawCategoryError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {



        async lawCustomToggle ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.get(V1API.law_custom_toggle + payload.law_slug, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchLaw');
            
            return response
        },

        
        //Law
        async addLaw ({commit, dispatch}, payload) {
            commit('LAW_LOADING', true);
            commit('LAW_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_law, payload.law, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
               
                return response

            }catch (e) {
                commit('LAW_ERROR', true);
            }finally {
                commit('LAW_LOADING', false);
            }
        },

        async fetchLawDetail ({ commit },payload) {
            commit('LAW_LOADING', true);
            commit('LAW_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_law_details + payload, config);
                let response = result.data.data

                commit('SET_LAW_DETAIL', response);

            } catch(error) {
                commit('LAW_ERROR', true);
            }
            finally {
                commit('LAW_LOADING', false);
            }
        },



        async fetchLaw ({ commit }) {
            commit('LAW_LOADING', true);
            commit('LAW_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.law_list, config);
                let response = result.data

                commit('SET_LAW', response.data);

            } catch(error) {
                commit('LAW_ERROR', true);
            }
            finally {
                commit('LAW_LOADING', false);
            }
        },


    
        async updateLaw ({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.patch(V1API.update_law+payload.id, payload.law, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
               
                return response

            }catch (e) {
                commit('LAW_ERROR', true);
            }finally {
                commit('LAW_LOADING', false);
            }
        },

        
        async deleteLaw({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.law_delete + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchLaw')
            
            return response
        },


        

        // Law Category
        async addLawCategory ({commit, dispatch}, payload) {
            commit('LAW_CATEGORY_LOADING', true);
            commit('LAW_CATEGORY_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_law_category, payload, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchLawCategory')
                return response

            }catch (e) {
                commit('LAW_CATEGORY_ERROR', true);
            }finally {
                commit('LAW_CATEGORY_LOADING', false);
            }
        },


        async fetchLawCategory ({ commit }) {
            commit('LAW_CATEGORY_LOADING', true);
            commit('LAW_CATEGORY_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.law_category_list, config);
                let response = result.data

                commit('SET_LAW_CATEGORY', response.data);

            } catch(error) {
                commit('LAW_CATEGORY_ERROR', true);
            }
            finally {
                commit('LAW_CATEGORY_LOADING', false);
            }
           
        },


    
        async updateLawCategory ({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try {

                let response = await axios.patch(V1API.update_law_category+payload.id,{title:payload.title},config).then(result=>{
                    let data = result.data
                    return data

                });
                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchLawCategory')
                return response

            }catch (e) {
                commit('LAW_CATEGORY_ERROR', true);
            }finally {
                commit('LAW_CATEGORY_LOADING', false);
            }
        },

        
        async deleteLawCategory({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_law_category + payload, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchLawCategory')
            
            return response
        },










       








    },

    getters: {}
}
