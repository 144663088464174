import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'SLIDER_UPDATE',
    smsDelete : 'SLIDER_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {
        
        slider:[],
      
        slider_detail:{},

        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        sliderLoading: false,
        sliderError: false,

        
       
        ...store
    },

    mutations : {
        ...mutations,

        SET_SLIDER(state, slider) {
            state.slider = slider;
        },

        SET_SLIDER_DETAIL(state, detail) {
            state.slider_detail = detail;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },
        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },


        SLIDER_LOADING (state, loading) {
            state.sliderLoading = loading;
        },

        SLIDER_ERROR (state, hasError) {
            state.sliderError = hasError;
        },
        


       
    },

    actions: {
        //Slider
        async addSlider ({commit, dispatch}, payload) {
            commit('SLIDER_LOADING', true);
            commit('SLIDER_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_slider, payload.slider, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchSlider')
               
                return response

            }catch (e) {
                commit('SLIDER_ERROR', true);
            }finally {
                commit('SLIDER_LOADING', false);
            }
        },

        async fetchSliderDetail ({ commit },payload) {
            commit('SLIDER_LOADING', true);
            commit('SLIDER_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_slider_details + payload, config);
                let response = result.data.data

                commit('SET_SLIDER_DETAIL', response);

            } catch(error) {
                commit('SLIDER_ERROR', true);
            }
            finally {
                commit('SLIDER_LOADING', false);
            }
        },



        async fetchSlider ({ commit }) {
            commit('SLIDER_LOADING', true);
            commit('SLIDER_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.slider_list, config);
                let response = result.data

                commit('SET_SLIDER', response.data);

            } catch(error) {
                commit('SLIDER_ERROR', true);
            }
            finally {
                commit('SLIDER_LOADING', false);
            }
        },


    
        async updateSlider ({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.patch(V1API.update_slider+payload.id, payload.slider, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchSlider')
                return response

            }catch (e) {
                commit('SLIDER_ERROR', true);
            }finally {
                commit('SLIDER_LOADING', false);
            }
        },

        
        async deleteSlider({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.slider_delete + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchSlider')
            
            return response
        },




    },

    getters: {}
}
