import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'REQUISITION_UPDATE',
    smsDelete : 'REQUISITION_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {
        
        pressReleases:[],
        press_detail:{},

        quotes:[],
        quotes_detail:{},

        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        pressLoading: false,
        pressError: false,

        quotesLoading: false,
        quotesError: false,

        

        
       

        ...store
    },

    mutations : {
        ...mutations,
       
      
        SET_PRESS_RELEASE(state, press) {
            state.pressReleases = press;
        },

        SET_PRESS_DETAIL(state, detail) {
            state.press_detail = detail;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        PRESS_LOADING (state, loading) {
            state.pressLoading = loading;
        },

        PRESS_ERROR (state, hasError) {
            state.pressError = hasError;
        },

        SET_QUOTES(state, quotes) {
            state.quotes = quotes;
        },

        SET_QUOTES_DETAIL(state, detail) {
            state.quotes_detail = detail;
        },

        QUOTES_LOADING (state, loading) {
            state.quotesLoading = loading;
        },

        QUOTES_ERROR (state, hasError) {
            state.quotesError = hasError;
        },
        

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        //Press
        async addPress ({commit, dispatch}, payload) {
            commit('PRESS_LOADING', true);
            commit('PRESS_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_press, payload.press, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
               
                return response

            }catch (e) {
                commit('PRESS_ERROR', true);
            }finally {
                commit('PRESS_LOADING', false);
            }
        },

        async fetchPressDetail ({ commit },payload) {
            commit('PRESS_LOADING', true);
            commit('PRESS_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.press_detail + payload, config);
                let response = result.data.data

                commit('SET_PRESS_DETAIL', response);

            } catch(error) {
                commit('PRESS_ERROR', true);
            }
            finally {
                commit('PRESS_LOADING', false);
            }
        },



        async fetchPress ({ commit }) {
            commit('PRESS_LOADING', true);
            commit('PRESS_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_press_list, config);
                let response = result.data

                commit('SET_PRESS_RELEASE', response.data);

            } catch(error) {
                commit('PRESS_ERROR', true);
            }
            finally {
                commit('PRESS_LOADING', false);
            }
        },


    
        async updatePress ({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.patch(V1API.update_press+payload.id, payload.press, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
               
                return response

            }catch (e) {
                commit('PRESS_ERROR', true);
            }finally {
                commit('PRESS_LOADING', false);
            }
        },

        
        async deletePress({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_press + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchPress')
            
            return response
        },

        //Quotes -->

        async addQuotes ({commit, dispatch}, payload) {
            commit('QUOTES_LOADING', true);
            commit('QUOTES_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_quotes, payload.quotes, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
               
                return response

            }catch (e) {
                commit('QUOTES_ERROR', true);
            }finally {
                commit('QUOTES_LOADING', false);
            }
        },

        async fetchQuotesDetail ({ commit },payload) {
            commit('QUOTES_LOADING', true);
            commit('QUOTES_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.quotes_detail + payload, config);
                let response = result.data.data

                commit('SET_QUOTES_DETAIL', response);

            } catch(error) {
                commit('QUOTES_ERROR', true);
            }
            finally {
                commit('QUOTES_LOADING', false);
            }
        },



        async fetchQuotes ({ commit }) {
            commit('QUOTES_LOADING', true);
            commit('QUOTES_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_quotes_list, config);
                let response = result.data

                commit('SET_QUOTES', response.data);

            } catch(error) {
                commit('QUOTES_ERROR', true);
            }
            finally {
                commit('QUOTES_LOADING', false);
            }
        },


    
        async updateQuotes ({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.patch(V1API.update_quotes+payload.id, payload.quotes, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
               
                return response

            }catch (e) {
                commit('QUOTES_ERROR', true);
            }finally {
                commit('QUOTES_LOADING', false);
            }
        },

        
        async deleteQuotes({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_quotes + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchQuotes')
            
            return response
        },

    },

    getters: {}
}
