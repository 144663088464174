<template>
<div>
    <Breadcrumbs main="Admin" title="Annual Reports" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-header">
                        <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                            <i class="fa fa-light fa-plus"></i>
                            <h7>Add New Annual Report</h7>
                        </button>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">

                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="report_list" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(image)="row">
                                        <img :src="host+row.item.image" alt="" style="width: 120px; height: 80px;">
                                    </template>

                                    <template #cell(pdf)="row">
                                        <a :href="host+row.item.pdf" alt="" target="_blank" class="link-style" style="cursor: pointer;">Click Here!</a>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">

                                            <div>
                                                <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteReport(data.item.id)" title="Delete"></feather>
                                            </div>

                                            <div @click="editReport(data.item)">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                            </div>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>

                        <div v-else>
                            <loader />
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Annual Report Create Modal -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Add Annual Report" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit()">
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Report Title</label>
                        <input class="form-control" type="text" placeholder="Enter report title" v-model="annual_report.title">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Report Date</label>
                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="annual_report.date"></datepicker>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Image</label>
                        <div class="flex items-center">
                            <img v-if="show_image" style="width: 120px; height: 80px;" :src="show_image">
                            <input :class="annual_report.image ? 'ml-4' : 'ml-0'" type="file" accept="image/*" @change="uploadImage">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Upload Attachment</label>
                        <b-form-file v-model="annual_report.pdf" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." @change="uploadPDF"></b-form-file>
                    </div>
                </div>
            </div>

        </form>
    </b-modal>

    <!-- Annual Report Edit Modal -->
    <b-modal id="modal-prevent-closing-edit" ref="modal" title="Edit Annual Report" @show="resetEditModal" @hidden="resetEditModal" @ok="handleEditOk">
        <form ref="form" @submit.stop.prevent="handleEditSubmit">

            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Report Title</label>
                        <input class="form-control" type="text" placeholder="Enter report title" v-model="edit_annual_report.title">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Report Date</label>
                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="edit_annual_report.date"></datepicker>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Image</label>
                        <div class="flex items-center">
                            <img v-if="show_image" style="width: 120px; height: 80px;" :src="show_image">
                            <input :class="edit_annual_report.image ? 'ml-4' : 'ml-0'" type="file" accept="image/*" @change="edit_uploadImage">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Upload Attachment</label>
                        <b-form-file v-model="edit_annual_report.pdf" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." @change="edit_uploadPDF"></b-form-file>
                    </div>
                </div>
            </div>

        </form>
    </b-modal>
    <!-- Partner Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this annual report?</div>
    </b-modal>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import moment from "moment";
import {
    mapState
} from 'vuex';
import {
    myHost
} from '../../../_helpers/host'
import js from '../../../auth/js';
import loader from '../loader/loader.vue'
export default {
    components: {
        Datepicker,
        loader
    },

    data() {
        return {
            host: myHost.host,
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'image',
                    label: 'Image',
                    sortable: true
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },

                {
                    key: 'date',
                    label: 'Date',
                    sortable: true
                },

                {
                    key: 'pdf',
                    label: 'Report',
                    sortable: true
                },
                

                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            annual_report: {
                title: '',
                image: null,
                pdf: null,
                date: '',

            },

            edit_annual_report: {
                id: '',
                title: '',
                image: null,
                pdf: null,
                date: '',
            },
            show_image: null,

            delete_value: null,

            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15],

        };
    },

    computed: {
        ...mapState({
            report_list: state => state.report.annual_reports,
            loading: state => state.report.partnerLoading,
            error: state => state.report.partnerError,

        }),

    },

    mounted() {
        this.$store.dispatch("report/fetchReport").then(() => {
            this.items = this.report_list
            this.totalRows = this.items.length;
        });

    },

    methods: {
        uploadImage(e) {
           
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.show_image = e.target.result
                this.annual_report.image = e.target.result;
            };
          
        },

        uploadPDF(e) {
            this.annual_report.pdf = e.target.files[0];
        },

        edit_uploadImage(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.show_image = e.target.result
                this.edit_annual_report.image = e.target.result;
            };
           
        },
        edit_uploadPDF(e) {
            this.edit_annual_report.pdf = e.target.files[0];
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        resetModal() {
            this.annual_report.title = ''
            this.annual_report.data = null
            this.annual_report.image = null
            this.annual_report.pdf = null
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()

        },

        handleSubmit() {

            if (this.annual_report.date !== null) {
                this.annual_report.date = moment(String(this.annual_report.date)).format("YYYY-MM-DD");
            }

            this.$store.dispatch("report/addReport", {
                report: this.annual_report
            }).then(response => {

                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.annual_report.title = null,
                    this.annual_report.date = null,
                    this.annual_report.image = null,
                    this.annual_report.pdf = null,
                    this.show_image = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        editReport(detail) {

            this.edit_annual_report.id = detail.id ? detail.id : null
            this.edit_annual_report.title = detail.title ? detail.title : null
            this.edit_annual_report.date = detail.date ? detail.date : null
            this.show_image = detail.image ? this.host + detail.image : null

        },

        resetEditModal() {
            this.edit_annual_report.id = ''
            this.edit_annual_report.title = ''
            this.edit_annual_report.data = null
            this.edit_annual_report.image = null
            this.edit_annual_report.pdf = null

        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },

        handleEditSubmit() {

            if (this.edit_annual_report.date !== null) {
                this.edit_annual_report.date = moment(String(this.edit_annual_report.date)).format("YYYY-MM-DD");
            }

            this.$store.dispatch("report/updateReport", {
                id: this.edit_annual_report.id,
                report: this.edit_annual_report,

            }).then(response => {

                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.edit_annual_report.title = null,
                    this.edit_annual_report.date = null,
                    this.edit_annual_report.image = null,
                    this.edit_annual_report.pdf = null,
                    this.show_image = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit')
            })
        },
        deleteReport(id) {
            this.delete_value = id
        },
        handleDeleteOk() {
            this.$store.dispatch("report/deleteReport", {
                delete: this.delete_value
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
