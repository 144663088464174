import { render, staticRenderFns } from "./what_we_do_create.vue?vue&type=template&id=46471f9d&"
import script from "./what_we_do_create.vue?vue&type=script&lang=js&"
export * from "./what_we_do_create.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports