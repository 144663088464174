<template>
<div>
    <Breadcrumbs main="Admin" title="Content" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-header">
                        <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                            <i class="fa fa-light fa-plus"></i>
                            <h7>Add New Content</h7>
                        </button>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">

                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="content_list" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(image)="row">
                                        <img :src="row.item.image" alt="" style="height: 80px;">
                                    </template>
                                    <template #cell(type)="row">
                                        <div v-if="row.item.type">
                                            <p v-if="row.item.type === 'campaign'">Campaign</p>
                                            <p v-if="row.item.type === 'law_n_policy'">Law & Policy</p>
                                            <p v-if="row.item.type === 'news'">News</p>
                                            <p v-if="row.item.type === 'what_we_do'">What We Do</p>
                                        </div>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">

                                            <div>
                                                <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteContent(data.item.id)" title="Delete"></feather>
                                            </div>

                                            <div @click="editContent(data.item)">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                            </div>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>

                        <div v-else>
                            <loader />
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Content Create Modal -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Add Content" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit()">

            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Title</label>
                        <input class="form-control" type="text" placeholder="Enter title" v-model="content.title">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Type</label>
                        <select class="form-control digits" v-model="content.type">
                            <option v-for="(type, index) in contentType" :key="index" :value="type.value">{{ type.name }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row" v-if="content.type">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label v-if="content.type === 'campaign'">Campaign</label>
                        <label v-if="content.type === 'law_n_policy'">Law & Policy</label>
                        <label v-if="content.type === 'news'">News</label>
                        <label v-if="content.type === 'what_we_do'">What We Do</label>
                      
                        <select class="form-control digits" v-model="content.type_slug" v-if="content.type === 'what_we_do'">
                            <option v-for="(type, index) in typeList" :key="index.id" :value="type.type">{{ type.type }}</option>
                        </select>
                        <select class="form-control digits" v-model="content.type_slug" v-else>
                            <option v-for="(type, index) in typeList" :key="index.id" :value="type.slug">{{ type.title }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Image (Ratio 1:1)</label>
                        <div class="flex items-center">
                            <img v-if="show_image" style="width: 120px; height: 80px;" :src="show_image">
                            <input :class="content.image ? 'ml-4' : 'ml-0'" type="file" accept="image/*" @change="uploadFile">
                        </div>
                    </div>
                </div>
            </div>

        </form>
    </b-modal>

    <!-- Content Edit Modal -->
    <b-modal id="modal-prevent-closing-edit" ref="modal" title="Edit Content" @show="resetEditModal" @hidden="resetEditModal" @ok="handleEditOk">
        <form ref="form" @submit.stop.prevent="handleEditSubmit">

            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Title</label>
                        <input class="form-control" type="text" placeholder="Enter title" v-model="edit_content.title">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Type</label>
                        <select class="form-control digits" v-model="edit_content.type">
                            <option v-for="(type, index) in contentType" :key="index" :value="type.value">{{ type.name }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row" v-if="edit_content.type">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label v-if="edit_content.type === 'campaign'">Campaign</label>
                        <label v-if="edit_content.type === 'law_n_policy'">Law & Policy</label>
                        <label v-if="edit_content.type === 'news'">News</label>
                        <label v-if="edit_content.type === 'what_we_do'">What We Do</label>
                        <select class="form-control digits" v-model="edit_content.type_slug" v-if="edit_content.type === 'what_we_do'">
                            <option v-for="(type, index) in typeList" :key="index.id" :value="type.type">{{ type.type }}</option>
                        </select>
                        <select class="form-control digits" v-model="edit_content.type_slug"  v-else>
                            <option v-for="(type, index) in typeList" :key="index.id" :value="type.slug">{{ type.title }}</option>
                        </select>
                        
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Image (Ratio 1:1)</label>
                        <div class="flex items-center">
                            <img v-if="show_image" style="width: 120px; height: 80px;" :src="show_image">
                            <input :class="edit_content.image ? 'ml-4' : 'ml-4'" type="file" accept="image/*" @change="EdituploadFile">
                        </div>
                    </div>
                </div>
            </div>

        </form>
    </b-modal>
    <!-- Content Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this content?</div>
    </b-modal>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import moment from "moment";
import {
    mapState
} from 'vuex';
import {
    myHost
} from '../../../_helpers/host'
import js from '../../../auth/js';
import loader from '../loader/loader.vue'
export default {
    components: {
        Datepicker,
        loader
    },

    data() {
        return {
            host: myHost.host,
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'image',
                    label: 'Image',
                    sortable: true
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'type',
                    label: 'Type',
                    sortable: true
                },
              
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],
            contentType: [{
                    name: 'Campaign',
                    value: 'campaign'
                },
                {
                    name: 'Law & Policy',
                    value: 'law_n_policy'
                },

                {
                    name: 'News',
                    value: 'news'
                },
                {
                    name: 'What We Do',
                    value: 'what_we_do'
                }

            ],

            content: {
                title: '',
                type: '',
                type_slug: '',
                image: '',

            },

            edit_content: {

                id: '',
                title: '',
                type: '',
                type_slug: '',
                image: '',

            },
            show_image: null,

            delete_value: null,

            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15],

            temp_quotation: {}
        };
    },

    computed: {
        ...mapState({
            content_list: state => state.content.contents,
            loading: state => state.content.contentLoading,
            error: state => state.content.contentError,
            campaignList: state => state.campaign.campaigns,
            lawList: state => state.law.laws,
            newsList: state => state.news.news,
            what_we_do_lists: state => state.whatWeDo.what_we_do,

        }),
        typeList() {
            switch (this.content.type || this.edit_content.type) {
                case 'campaign':
                    return this.campaignList;
                case 'law_n_policy':
                    return this.lawList;
                case 'news':
                    return this.newsList;
                case 'what_we_do':
                    return this.what_we_do_lists;
                default:
                    return [];
            }
        },

        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        },

    },

    mounted() {
        this.$store.dispatch("content/fetchContent").then(() => {
            this.items = this.content_list
            this.totalRows = this.items.length;
        });
        this.$store.dispatch("campaign/fetchCampaign");
        this.$store.dispatch("law/fetchLaw");
        this.$store.dispatch("news/fetchNews");
        this.$store.dispatch("whatWeDo/fetchWhatDo");
    },

    methods: {
        uploadFile(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.show_image = e.target.result
                this.content.image = e.target.result;
                this.edit_content.image = e.target.result;
            };
        },
        EdituploadFile(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.show_image = e.target.result
                this.edit_content.image = e.target.result;
            };
        },
        onFiltered(filteredItems) {

            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        resetModal() {
            this.content.title = null
            this.content.type = null
            this.content.type_slug = null
            this.content.image = null
            this.show_image = null
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()

        },

        handleSubmit() {

            this.$store.dispatch("content/addContent", {
                content: this.content
            }).then(response => {

                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.content.title = ''
                    this.content.type = null,
                    this.content.type_slug = null
                    this.content.image = null

                    

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        editContent(detail) {

            this.edit_content.id = detail.id ? detail.id : null
            this.edit_content.title = detail.title ? detail.title : null
            this.edit_content.type = detail.type ? detail.type : null
            this.edit_content.type_slug = detail.type_slug ? detail.type_slug : null
            this.show_image = detail.image ? detail.image : null

        },

        resetEditModal() {
            this.edit_content.title = null
            this.edit_content.type = null
            this.edit_content.type_slug = null
            this.edit_content.image = null
            this.show_image = null

        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },

        handleEditSubmit() {

            this.$store.dispatch("content/updateContent", {
                id: this.edit_content.id,
                content: this.edit_content,

            }).then(response => {

                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.edit_content.id = ''
                    this.edit_content.title = ''
                    this.edit_content.type = ''
                    this.edit_content.type_slug = ''
                    this.edit_content.image = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit')
            })
        },
        deleteContent(id) {
            this.delete_value = id
        },
        handleDeleteOk() {
            this.$store.dispatch("content/deleteContent", {
                delete: this.delete_value
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
