<template>
<div>
    <Breadcrumbs main="Admin" title="News" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header header-mobile">
                        <div class="d-flex flex-column">

                            <router-link :to="{ name: 'baseDetail', params: { slug: 'news' }}" class="mb-2">
                                <button class="btn btn-primary" type="submit" style="display: flex; gap: 12px; align-items: center;">
                                    <h7>Base Details</h7>
                                </button>
                            </router-link>

                            <router-link to="/admin/news-create">
                                <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                                    <i class="fa fa-light fa-plus"></i>
                                    <h7>Add New News</h7>
                                </button>
                            </router-link>
                        </div>

                        <router-link to="/admin/news-category">
                            <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                                <i class="fa fa-solid fa-bars"></i>
                                <h7>News Category</h7>
                            </button>
                        </router-link>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">
                                <div class="d-flex flex-column flex-xl-row " style="gap: 10px;">
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                </div>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(thumbnail)="row">
                                        <img :src="host+row.item.thumbnail" alt="" style="height: 80px;">
                                    </template>
                                    <template #cell(category)="row">
                                        <div v-if="row.item.category !== null">
                                            {{ row.item.category.title }}
                                        </div>
                                    </template>
                                    <template #cell(description)="row">
                                        <div v-if="row.item.description" style="min-width: 300px;">
                                            {{ truncateText(row.item.description,40) }}
                                        </div>
                                    </template>
                                    <template #cell(custom)="data">
                                        <span v-if="data.item.custom == true" class="d-flex flex-column justify-content-center">
                                           
                                            <b-badge v-b-tooltip.hover title="Toggle" variant="primary" @click="toggleNews(data.item.slug)" style="cursor: pointer; max-width: fit-content;">Yes</b-badge>
                                            <router-link :to="`/admin/custom-news/${data.item.slug}`">
                                                <b-badge v-b-tooltip.hover variant="primary"> Details </b-badge>
                                            </router-link>
                                            
                                        </span>
                                        <span v-if="data.item.custom == false">
                                            <router-link :to="`/admin/custom-news/${data.item.slug}`">
                                                <b-badge v-b-tooltip.hover variant="danger"> No </b-badge>
                                            </router-link>
                                        </span>

                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteNews(data.item.id)" title="Delete"></feather>
                                            <router-link :to="`/admin/news-edit/${data.item.slug}`">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                            </router-link>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>

                        <div v-else>
                            <loader />
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this news?</div>
    </b-modal>

</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import js from '../../../auth/js';
import {
    myHost
} from '../../../_helpers/host'
import loader from '../loader/loader.vue'
export default {
    components: {
        loader,
    },
    data() {
        return {
            host: myHost.host,
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'thumbnail',
                    label: 'Image',
                    sortable: true,

                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'category',
                    label: 'Category',
                    sortable: true
                },
                {
                    key: 'description',
                    label: 'Description',
                    sortable: true
                },
                {
                    key: 'custom',
                    label: 'Custom',
                    sortable: true
                },

                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            filter: null,
            delete_value: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15]
        };
    },

    computed: {
        ...mapState({
            items: state => state.news.news,
            loading: state => state.news.newsLoading,
            error: state => state.news.newsError,
        }),

        sortOptions() {
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        }
    },

    mounted() {

        this.$store.dispatch("news/fetchNews").then(() => {
            this.totalRows = this.items;

        });

    },

    watch: {

    },

    methods: {
        toggleNews(data) {
            this.$store.dispatch("news/newsCustomToggle", {
                news_slug: data,


            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.udate_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },


        truncateText(text, maxLength) {
            if (text.length > maxLength) {
                return text.substring(0, maxLength) + '...';
            } else {
                return text;
            }
        },

        resetModal() {
            this.selectedStatus = ''
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        deleteNews(id) {
            this.delete_value = id
        },

        handleDeleteOk() {
            this.$store.dispatch("news/deleteNews", {
                delete: this.delete_value,
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

    }
};
</script>

<style scoped>
.header-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 460px) {
    .header-mobile {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: start;
    }
}
</style>
