var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-12 p-0"},[_c('div',{staticClass:"login-card"},[_c('div',[_vm._m(0),_c('div',{staticClass:"login-main"},[_c('b-card',[_c('b-card-text',[_c('form',{staticClass:"theme-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",class:{ 'is-invalid': _vm.submitted && !_vm.email },attrs:{"type":"text","name":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.submitted && !_vm.email),expression:"submitted && !email"}],staticClass:"invalid-feedback"},[_vm._v(" Email is required ")])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"htmlFor":"password"}},[_vm._v("Password")]),_c('div',{staticStyle:{"position":"relative","width":"100%"}},[_c('div',{staticStyle:{"position":"absolute","top":"0px","bottom":"0px","right":"0px","padding-right":"28px","display":"flex","align-items":"center"}},[_c('i',{class:_vm.passwordToggleIcon,staticStyle:{"position":"absolute","display":"flex","justify-content":"center","cursor":"pointer"},on:{"click":_vm.togglePasswordVisibility}})]),((_vm.passwordFieldType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordjwt),expression:"passwordjwt"}],staticClass:"form-control",class:{
                                                            'is-invalid': _vm.submitted && !_vm.passwordjwt,
                                                        },attrs:{"name":"password","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.passwordjwt)?_vm._i(_vm.passwordjwt,null)>-1:(_vm.passwordjwt)},on:{"change":function($event){var $$a=_vm.passwordjwt,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.passwordjwt=$$a.concat([$$v]))}else{$$i>-1&&(_vm.passwordjwt=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.passwordjwt=$$c}}}}):((_vm.passwordFieldType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordjwt),expression:"passwordjwt"}],staticClass:"form-control",class:{
                                                            'is-invalid': _vm.submitted && !_vm.passwordjwt,
                                                        },attrs:{"name":"password","type":"radio"},domProps:{"checked":_vm._q(_vm.passwordjwt,null)},on:{"change":function($event){_vm.passwordjwt=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordjwt),expression:"passwordjwt"}],staticClass:"form-control",class:{
                                                            'is-invalid': _vm.submitted && !_vm.passwordjwt,
                                                        },attrs:{"name":"password","type":_vm.passwordFieldType},domProps:{"value":(_vm.passwordjwt)},on:{"input":function($event){if($event.target.composing)return;_vm.passwordjwt=$event.target.value}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.submitted && !_vm.passwordjwt),expression:"submitted && !passwordjwt"}],staticClass:"invalid-feedback"},[_vm._v(" Password is required ")])])]),_c('div',{staticClass:"form-group mt-3 mb-0"},[_c('button',{staticClass:"btn btn-primary btn-block",attrs:{"disabled":_vm.loggingIn}},[_vm._v(" Login ")])]),_c('p',{staticClass:"mt-2 mb-0"},[_vm._v(" Forgot password? "),_c('router-link',{staticClass:"ml-1",attrs:{"tag":"a","to":"/auth/reset-password"}},[_vm._v(" Reset Password ")])],1)])])],1)],1)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{staticClass:"logo"},[_c('img',{staticClass:"img-fluid for-light",staticStyle:{"max-width":"40%"},attrs:{"src":require("../assets/images/logo/logo.svg"),"alt":"looginpage"}}),_c('img',{staticClass:"img-fluid for-dark",staticStyle:{"max-width":"40%"},attrs:{"src":require("../assets/images/logo/logo.svg"),"alt":"looginpage"}})])])
}]

export { render, staticRenderFns }