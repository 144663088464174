import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'REQUISITION_UPDATE',
    smsDelete : 'REQUISITION_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {
        
        campaigns:[],
        campaignCategory:[],
        campaign_detail:{},

        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        campaignLoading: false,
        campaignError: false,

        

        campaignCategoryLoading: false,
        campaignCategoryError: false,

       

        ...store
    },

    mutations : {
        ...mutations,
       
        SET_CAMPAIGN_CATEGORY(state, category) {
            state.campaignCategory = category;
        },
        SET_CAMPAIGN(state, campaign) {
            state.campaigns = campaign;
        },

        SET_CAMPAIGN_DETAIL(state, detail) {
            state.campaign_detail = detail;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },


        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },


        CAMPAIGN_LOADING (state, loading) {
            state.campaignLoading = loading;
        },

        CAMPAIGN_ERROR (state, hasError) {
            state.campaignError = hasError;
        },
        
        CAMPAIGN_CATEGORY_LOADING (state, loading) {
            state.campaignCategoryLoading = loading;
        },

        CAMPAIGN_CATEGORY_ERROR (state, hasError) {
            state.campaignCategoryError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {


        async campaignCustomToggle ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.get(V1API.campaign_custom_toggle + payload.campaign_slug, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchCampaign');
            
            return response
        },

        //Campaign
        async addCampaign ({commit, dispatch}, payload) {
            commit('CAMPAIGN_LOADING', true);
            commit('CAMPAIGN_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_campaign, payload.campaign, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
               
                return response

            }catch (e) {
                commit('CAMPAIGN_ERROR', true);
            }finally {
                commit('CAMPAIGN_LOADING', false);
            }
        },

        async fetchCampaignDetail ({ commit },payload) {
            commit('CAMPAIGN_LOADING', true);
            commit('CAMPAIGN_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_campaign_details + payload, config);
                let response = result.data.data

                commit('SET_CAMPAIGN_DETAIL', response);

            } catch(error) {
                commit('CAMPAIGN_ERROR', true);
            }
            finally {
                commit('CAMPAIGN_LOADING', false);
            }
        },



        async fetchCampaign ({ commit }) {
            commit('CAMPAIGN_LOADING', true);
            commit('CAMPAIGN_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.campaign_list, config);
                let response = result.data

                commit('SET_CAMPAIGN', response.data);

            }catch(error) {
                commit('CAMPAIGN_ERROR', true);
            }
            finally {
                commit('CAMPAIGN_LOADING', false);
            }
        },


    
        async updateCampaign ({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.patch(V1API.update_campaign+payload.id, payload.campaign, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
               
                return response

            }catch (e) {
                commit('CAMPAIGN_ERROR', true);
            }finally {
                commit('CAMPAIGN_LOADING', false);
            }
        },

        
        async deleteCampaign({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.campaign_delete + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchCampaign')
            
            return response
        },


        

        // Campaign Category
        async addCampaignCategory ({commit, dispatch}, payload) {
            commit('CAMPAIGN_CATEGORY_LOADING', true);
            commit('CAMPAIGN_CATEGORY_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_campaign_category, payload, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchCampaignCategory')
                return response

            }catch (e) {
                commit('CAMPAIGN_CATEGORY_ERROR', true);
            }finally {
                commit('CAMPAIGN_CATEGORY_LOADING', false);
            }
        },


        async fetchCampaignCategory ({ commit }) {
            commit('CAMPAIGN_CATEGORY_LOADING', true);
            commit('CAMPAIGN_CATEGORY_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.campaign_category_list, config);
                let response = result.data

                commit('SET_CAMPAIGN_CATEGORY', response.data);

            } catch(error) {
                commit('CAMPAIGN_CATEGORY_ERROR', true);
            }
            finally {
                commit('CAMPAIGN_CATEGORY_LOADING', false);
            }
           
        },


    
        async updateCampaignCategory ({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.patch(V1API.update_campaign_category+payload.id,payload.title,config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchCampaignCategory')
                return response

            }catch (e) {
                commit('CAMPAIGN_CATEGORY_ERROR', true);
            }finally {
                commit('CAMPAIGN_CATEGORY_LOADING', false);
            }
        },

        
        async deleteCampaignCategory({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_campaign_category + payload, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchCampaignCategory')
            
            return response
        },










       








    },

    getters: {}
}
