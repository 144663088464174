<template>
    <div>
        <Breadcrumbs main="Admin" title="Partners" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
    
                        <div class="card-header">
                            <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                                <i class="fa fa-light fa-plus"></i>
                                <h7>Add New Partner</h7>
                            </button>
                        </div>
    
                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">
    
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
    
                                </b-col>
    
                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
    
                            <div v-if="!loading && !error">
                                <div class="table-responsive datatable-vue">
                                    <b-table show-empty stacked="md" :items="partner_list" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                        <template #cell(index)="data">
                                            {{ (currentPage - 1) * perPage + data.index + 1 }}
                                        </template>
    
                                        <template #cell(image)="row">
                                            <img :src="host+row.item.image" alt="" style="width: 120px; height: 80px;">
                                        </template>
    
                                
    
                                        <template #cell(action)="data">
                                            <span style="display: flex; gap: 12px;">
    
                                                <div>
                                                    <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deletePartner(data.item.id)" title="Delete"></feather>
                                                </div>
    
                                                <div @click="editPartner(data.item)">
                                                    <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                                </div>
                                            </span>
                                        </template>
                                    </b-table>
                                </div>
                            </div>
    
                            <div v-else>
                                <loader />
                            </div>
    
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                            </b-col>
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    
        <!-- Gallery Create Modal -->
        <b-modal id="modal-prevent-closing" ref="modal" title="Add Partner" @show="resetModal" @hidden="resetModal" @ok="handleOk">
            <form ref="form" @submit.stop.prevent="handleSubmit()">
               
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label>Image</label>
                            <div class="flex items-center">
                                <img v-if="show_image" style="width: 120px; height: 80px;" :src="show_image">
                                <input :class="partner.image ? 'ml-4' : 'ml-0'" type="file" accept="image/*" @change="uploadFile">
                            </div>
                        </div>
                    </div>
                </div>
    
            </form>
        </b-modal>
    
        <!-- Partner Edit Modal -->
        <b-modal id="modal-prevent-closing-edit" ref="modal" title="Edit Partner" @show="resetEditModal" @hidden="resetEditModal" @ok="handleEditOk">
            <form ref="form" @submit.stop.prevent="handleEditSubmit">
              
              
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label>Image</label>
                            <div class="flex items-center">
                                <img v-if="show_image" style="width: 120px; height: 80px;" :src="show_image">
                                <input :class="edit_partner.image ? 'ml-4' : 'ml-4'" type="file" accept="image/*" @change="EdituploadFile">
                            </div>
                        </div>
                    </div>
                </div>
    
            </form>
        </b-modal>
        <!-- Partner Delete Modal -->
        <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
            <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this partner?</div>
        </b-modal>
    </div>
    </template>
    
    <script>
    import Datepicker from 'vuejs-datepicker';
    import Swal from 'sweetalert2/dist/sweetalert2.js'
    import 'sweetalert2/src/sweetalert2.scss'
    import moment from "moment";
    import {
        mapState
    } from 'vuex';
    import {
        myHost
    } from '../../../_helpers/host'
    import js from '../../../auth/js';
    import loader from '../loader/loader.vue'
    export default {
        components: {
            Datepicker,
            loader
        },
    
        data() {
            return {
                host: myHost.host,
                tablefields: [{
                        key: 'index',
                        label: '#'
                    },
                    {
                        key: 'image',
                        label: 'Image',
                        sortable: true
                    },
        
                    {
                        key: 'action',
                        label: 'Action',
                        sortable: false
                    }
                ],
               
    
                partner: {
                    image: '',
    
                },
    
                edit_partner: {
                    image: '',
    
                },
                show_image: null,
    
                delete_value: null,
    
                filter: null,
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [5, 10, 15],
    
            
            };
        },
    
        computed: {
            ...mapState({
                partner_list: state => state.partner.partner,
                loading: state => state.partner.partnerLoading,
                error: state => state.partner.partnerError,
    
            }),
    
        
    
        },
    
        mounted() {
            this.$store.dispatch("partner/fetchPartner").then(() => {
                this.items = this.partner_list
                this.totalRows = this.items.length;
            });
    
        },
    
        methods: {
            uploadFile(e) {
                const image = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = e => {
                    this.show_image = e.target.result
                    this.partner.image = e.target.result;
    
                };
            },
            EdituploadFile(e) {
                const image = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = e => {
                    this.show_image = e.target.result
                    this.edit_partner.image = e.target.result;
                };
            },
            onFiltered(filteredItems) {
    
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
    
            resetModal() {
                this.department.name = ''
            },
    
            handleOk(bvModalEvent) {
                bvModalEvent.preventDefault()
                this.handleSubmit()
    
            },
    
            handleSubmit() {
    
                this.$store.dispatch("partner/addPartner", {
                    partner: this.partner
                }).then(response => {
    
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
    
                        this.partner.title = null,
                        this.partner.image = null
                        this.show_image = null
    
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
    
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-prevent-closing')
                })
            },
    
            editPartner(detail) {
    
                this.edit_partner.id = detail.id ? detail.id : null
                this.edit_partner.title = detail.title ? detail.title : null
                this.show_image = detail.image ? this.host + detail.image : null
    
            },
    
            resetEditModal() {
                this.edit_partner.title = null
                this.edit_partner.image = null,
                this.show_image = null
    
            },
    
            handleEditOk(bvModalEvent) {
                bvModalEvent.preventDefault()
                this.handleEditSubmit()
            },
    
            handleEditSubmit() {
    
                this.$store.dispatch("partner/updatePartner", {
                    id: this.edit_partner.id,
                    partner: this.edit_partner,
    
                }).then(response => {
    
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
    
                        this.edit_partner.title = null
                        this.edit_partner.image = null
                        this.show_image = null
                    
    
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
    
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-prevent-closing-edit')
                })
            },
            deletePartner(id) {
                this.delete_value = id
            },
            handleDeleteOk() {
                this.$store.dispatch("partner/deletePartner", {
                    delete: this.delete_value
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
    
                        this.delete_value = null
    
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            }
        }
    };
    </script>
    