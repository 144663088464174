<template>
<div>
    <Breadcrumbs main="Campaign" title="Campaign Edit" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <px-card v-if="!loading && !error">
                    <div slot="with-padding">
                        <div class="form theme-form">

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Title</label>
                                        <input class="form-control" type="text" placeholder="Enter campaign title" v-model="details.title">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Category</label>
                                        <select class="form-control digits" v-model="details.category">
                                            <option v-for="(item, index) in category_list" :key="index" :value="item.id">{{ item.title }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Date</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="details.date"></datepicker>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Featured</label>
                                        <select class="form-control digits" v-model="details.featured" style="height: 42px;">
                                            <option v-for="(status, index) in statuses" :key="index" :value="status.value">{{ status.label }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Banner</label>
                                        <div class="flex items-center">
                                            <img v-if="show_image" style="width: 120px; height: 80px;" :src="show_image">
                                            <input :class="details.image ? 'ml-4' : 'ml-4'" type="file" accept="image/*" @change="uploadFile">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Thumbnail</label>
                                        <div class="flex items-center">
                                            <img v-if="show_thumnail" style="width: 120px; height: 80px;" :src="show_thumnail">
                                            <input :class="details.thumbnail ? 'ml-4' : 'ml-4'" type="file" accept="image/*" @change="uploadThumbnailFile">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Details</label>
                                        <ckeditor :editor="editor" placeholder="Enter short description..." v-model="details.description"></ckeditor>
                                      

                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button @click="onSubmit" class="btn btn-success mr-3" :disabled="isDisabled">Update</button>
                                        <router-link to="/admin/campaign">
                                            <button class="btn btn-danger" href="#">Go Back</button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </px-card>
                <px-card v-else>
                    <div slot="with-padding">
                        <loader />
                    </div>
                </px-card>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
</div>
</template>

    
<script>
import {
    mapState
} from 'vuex';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from "moment";
import Multiselect from 'vue-multiselect';
import 'sweetalert2/src/sweetalert2.scss';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import treeVue from '../../advance/tree.vue';
import {myHost} from '../../../_helpers/host'
import loader from '../loader/loader.vue'
export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        Multiselect,
        loader
    },

    data() {
        return {
            host:myHost.host,
            isDisabled: false,
            editor: ClassicEditor,
            details: {
                title: null,
                description: null,
                image: null,
                featured: null,
                date: null,
                thumbnail: null,
                category: null,
            },
            show_image: null,
            show_thumnail: null,

            statuses: [{
                    label: 'Yes',
                    value: true
                },
                {
                    label: 'No',
                    value: false
                }
            ],

        };
    },

    watch: {
        campaignDetails(oldValue, newValue) {

            this.details.title = this.campaignDetails.title
            this.details.description = this.campaignDetails.description
            this.details.featured = this.campaignDetails.featured
            this.details.date = this.campaignDetails.date
            this.details.category = this.campaignDetails.category? this.campaignDetails.category.id : null

            this.show_image = this.campaignDetails.image ? this.host + this.campaignDetails.image : null
            this.show_thumnail = this.campaignDetails.thumbnail ? this.host + this.campaignDetails.thumbnail : null

        },
    },

    computed: {
        ...mapState({
            category_list: state => state.campaign.campaignCategory,
            campaignDetails: state => state.campaign.campaign_detail,
            loading: state => state.campaign.campaignLoading,
            error: state => state.campaign.campaignError,
        }),

    },

    mounted() {
        this.$store.dispatch("campaign/fetchCampaignCategory")
        this.$store.dispatch("campaign/fetchCampaignDetail", this.$route.params.slug)
    },

    methods: {

        uploadFile(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.show_image = e.target.result
                this.details.image = e.target.result;
            };
        },
        uploadThumbnailFile(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.show_thumnail = e.target.result
                this.details.thumbnail = e.target.result;
            };
        },

        onSubmit() {
            this.isDisabled = true
            if (this.details.date !== null) {
                this.details.date = moment(String(this.details.date)).format("YYYY-MM-DD");
            }

            this.$store.dispatch("campaign/updateCampaign", {
                campaign: this.details,
                id:this.$route.params.slug
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                        this.isDisabled = false,
                        this.details.title = null,
                        this.details.description = null,
                        this.details.image = null,
                        this.details.featured = null,
                        this.details.date = null,
                        this.details.category = null,
                        this.details.thumbnail = null

                    setTimeout(() => {
                        this.$router.push('/admin/campaign')
                    }, 2000)

                } else {
                    this.isDisabled = false
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }

            })
        }
    }
};
</script>
