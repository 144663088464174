import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'QNA_UPDATE',
    smsDelete : 'QNA_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {
        
        qna:[],
      
        qna_detail:{},

        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        qnaLoading: false,
        qnaError: false,

        
       
        ...store
    },

    mutations : {
        ...mutations,

        SET_QNA(state, law) {
            state.qna = law;
        },

        SET_QNA_DETAIL(state, detail) {
            state.qna_detail = detail;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },
        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },


        QNA_LOADING (state, loading) {
            state.qnaLoading = loading;
        },

        QNA_ERROR (state, hasError) {
            state.qnaError = hasError;
        },
        


       
    },

    actions: {
        //QnA
        async addQNA ({commit, dispatch}, payload) {
            commit('QNA_LOADING', true);
            commit('QNA_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_qna, payload.qna, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchQNA')
               
                return response

            }catch (e) {
                commit('QNA_ERROR', true);
            }finally {
                commit('QNA_LOADING', false);
            }
        },

        async fetchQNADetail ({ commit },payload) {
            commit('QNA_LOADING', true);
            commit('QNA_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_qna_details + payload, config);
                let response = result.data.data

                commit('SET_QNA_DETAIL', response);

            } catch(error) {
                commit('QNA_ERROR', true);
            }
            finally {
                commit('QNA_LOADING', false);
            }
        },



        async fetchQNA ({ commit }) {
            commit('QNA_LOADING', true);
            commit('QNA_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.qna_list, config);
                let response = result.data

                commit('SET_QNA', response.data);

            } catch(error) {
                commit('QNA_ERROR', true);
            }
            finally {
                commit('QNA_LOADING', false);
            }
        },


    
        async updateQNA ({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.patch(V1API.update_qna+payload.id, payload.qna, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchQNA')
                return response

            }catch (e) {
                commit('QNA_ERROR', true);
            }finally {
                commit('QNA_LOADING', false);
            }
        },

        
        async deleteQNA({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.qna_delete + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchQNA')
            
            return response
        },

        async toggleQNAVisiblity ({commit, dispatch}, payload) {
            commit('QNA_LOADING', true);
            commit('QNA_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.get(V1API.toogle_qna_visiblity + payload.id , config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchQNA')
                return response

            }catch (e) {
                commit('QNA_ERROR', true);
            }finally {
                commit('QNA_LOADING', false);
            }
        },


        











       








    },

    getters: {}
}
