<template>
<div>
    <Breadcrumbs main="Admin" title="Site Settings" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input class="form-control" type="text" placeholder="Enter email" v-model="details.email">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Phone Number</label>
                                        <input class="form-control" type="text" placeholder="Enter phone number" v-model="details.phone_number">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Address</label>
                                        <input class="form-control" type="text" placeholder="Enter address" v-model="details.address">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Message (20 to 40 words for contact page)</label>
                                        <input class="form-control" type="text" placeholder="Enter message" v-model="details.message">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Facebook</label>
                                        <input class="form-control" type="text" placeholder="Enter Facebook URL" v-model="details.fb">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Instagram</label>
                                        <input class="form-control" type="text" placeholder="Enter Instagram URL" v-model="details.insta">
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Twitter</label>
                                        <input class="form-control" type="text" placeholder="Enter Twitter URL" v-model="details.twit">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>LinkedIn</label>
                                        <input class="form-control" type="text" placeholder="Enter LinkedIn URL" v-model="details.linked">
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label>Upload Logo</label>
                                            <div class="flex items-center">
                                                <img v-if="show_logo" style="height: 120px; width: 120px;" :src="show_logo">
                                                <input :class="details.logo ? 'ml-4' : 'ml-4'" type="file" accept="image/*" @change="uploadFile">
                                            </div>
                                        </div>
                                    </div>

                                </div> -->

                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button v-if="setting == null" @click="onSubmit()" class="btn btn-success mr-3">Create</button>
                                        <button v-else @click="onSubmit()" class="btn btn-primary mr-3">Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </px-card>
            </div>

            <!-- <div class="col-sm-12" v-else>
                    <loader/>
                </div> -->
        </div>
    </div>

</div>
</template>

    
<script>
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import moment from "moment";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    myHost
} from '../../../_helpers/host'
import {
    mapState
} from 'vuex';
import loader from '../loader/loader.vue'
export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        loader
    },

    data() {
        return {
            host: myHost.host,
            editor: ClassicEditor,
            details: {
                message: '',
                email: '',
                phone_number: '',
                logo: null,
                address: '',
                twit:'',
                fb:'',
                linked:'',
                insta:'',

            },
            show_logo: null,

        };
    },

    watch: {
        setting(oldValue, newValue) {
            this.details.message = this.setting.message ? this.setting.message : null
            this.details.phone_number = this.setting.phone_number ? this.setting.phone_number : null
            this.details.email = this.setting.email ? this.setting.email : null
            this.details.address = this.setting.address ? this.setting.address : null
            this.details.twit = this.setting.twit ? this.setting.twit : null
            this.details.fb = this.setting.fb ? this.setting.fb : null
            this.details.linked = this.setting.linked ? this.setting.linked : null
            this.details.insta = this.setting.insta ? this.setting.insta : null

            this.show_logo = this.setting.logo ? this.host + this.setting.logo : null

        },
    },

    mounted() {
        this.$store.dispatch("siteSetting/fetchSiteDetail")
    },

    computed: {
        ...mapState({
            setting: state => state.siteSetting.site_detail,
            loading: state => state.siteSetting.baseLoading,
            error: state => state.siteSetting.baseError,
        })

    },
    methods: {
        truncateText(text, maxLength) {
            if (text.length > maxLength) {
                return text.substring(0, maxLength) + '...';
            } else {
                return text;
            }
        },

        uploadFile(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.show_logo = e.target.result
                this.details.logo = e.target.result;
            };
        },

        onSubmit() {

            this.$store.dispatch("siteSetting/addSiteDetail", {
                site_detail: this.details
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.details.message = '',
                        this.details.email = '',
                        this.details.phone_number = '',
                        this.details.logo = null,
                        this.details.address = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

    }
};
</script>
