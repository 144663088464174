<template>
<div>
    <Breadcrumbs main="Admin" title="Counter" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-header">
                        <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                            <i class="fa fa-light fa-plus"></i>
                            <h7>Add New Counter</h7>
                        </button>
                        <h6 class="mt-4">Upload exactly 4 counters for each type to prevent breaking the UI.</h6>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">

                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="counter_list" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(type)="row">
                                        <div v-if="row.item.type">
                                            <p v-if="row.item.type === 'campaigns'">Campaigns</p>
                                            <p v-if="row.item.type === 'home'">Home</p>
                                            <p v-if="row.item.type === 'news'">News</p>
                                        </div>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">

                                            <div>
                                                <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteGallery(data.item.id)" title="Delete"></feather>
                                            </div>

                                            <div @click="editGallery(data.item)">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                            </div>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>

                        <div v-else>
                            <loader />
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Counter Create Modal -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Add Counter" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit()">
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Type</label>
                        <select class="form-control digits" v-model="counter.type">
                            <option v-for="(type, index) in counterType" :key="index" :value="type.value">{{ type.name }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Title</label>
                        <input class="form-control" type="text" placeholder="Enter title" v-model="counter.title">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Value</label>
                        <input class="form-control" type="text" placeholder="Enter value" v-model="counter.value">
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Suffix</label>
                        <input class="form-control" type="text" placeholder="Enter title" v-model="counter.suffix">
                    </div>
                </div>
            </div>

        </form>
    </b-modal>

    <!-- Gallery Edit Modal -->
    <b-modal id="modal-prevent-closing-edit" ref="modal" title="Edit Counter" @show="resetEditModal" @hidden="resetEditModal" @ok="handleEditOk">
        <form ref="form" @submit.stop.prevent="handleEditSubmit">
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Type</label>
                        <select class="form-control digits" v-model="edit_counter.type">
                            <option v-for="(type, index) in counterType" :key="index" :value="type.value">{{ type.name }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Title</label>
                        <input class="form-control" type="text" placeholder="Enter title" v-model="edit_counter.title">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Value</label>
                        <input class="form-control" type="text" placeholder="Enter value" v-model="edit_counter.value">
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Suffix</label>
                        <input class="form-control" type="text" placeholder="Enter title" v-model="edit_counter.suffix">
                    </div>
                </div>
            </div>

        </form>
    </b-modal>
    <!-- Gallery Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this gallery?</div>
    </b-modal>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import moment from "moment";
import {
    mapState
} from 'vuex';
import {
    myHost
} from '../../../_helpers/host'
import js from '../../../auth/js';
import loader from '../loader/loader.vue'
export default {
    components: {
        Datepicker,
        loader
    },

    data() {
        return {
            host: myHost.host,
            tablefields: [{
                    key: 'index',
                    label: '#'
                },

                {
                    key: 'type',
                    label: 'Type',
                    sortable: true
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'value',
                    label: 'Value',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],
            counterType: [{
                    name: 'Campaigns',
                    value: 'campaigns'
                },
                {
                    name: 'Home',
                    value: 'home'
                },

                {
                    name: 'News',
                    value: 'news'
                }

            ],

            counter: {
                title: '',
                value: '',
                suffix: '',
                type: '',

            },

            edit_counter: {
                id:'',
                title: '',
                value: '',
                suffix: '',
                type: '',

            },

            delete_value: null,

            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15],

        };
    },

    computed: {
        ...mapState({
            counter_list: state => state.counter.counters,
            loading: state => state.counter.counterLoading,
            error: state => state.counter.counterError,

        }),

    },

    mounted() {
        this.$store.dispatch("counter/fetchCounter").then(() => {
            this.items = this.counter_list
            this.totalRows = this.items.length;
        });

    },

    methods: {

        onFiltered(filteredItems) {

            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        resetModal() {
            this.counter.type = null
            this.counter.title = null
            this.counter.value = null
            this.counter.suffix = null
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()

        },

        handleSubmit() {

            this.$store.dispatch("counter/addCounter", {
                counter: this.counter,
                counter_type: this.counter.type
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.counter.type = null
                    this.counter.title = null
                    this.counter.value = null
                    this.counter.suffix = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })


        },

        editGallery(detail) {
            this.edit_counter.id = detail.id ? detail.id : null
            this.edit_counter.type = detail.type ? detail.type : null
            this.edit_counter.title = detail.title ? detail.title : null
            this.edit_counter.value = detail.value ? detail.value : null
            this.edit_counter.suffix = detail.suffix ? detail.suffix : null

        },

        resetEditModal() {
            this.edit_counter.id = null
            this.edit_counter.type = null
            this.edit_counter.title = null
            this.edit_counter.value = null
            this.edit_counter.suffix = null

        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },

        handleEditSubmit() {

            this.$store.dispatch("counter/UpdateCounter", {
                counter: this.edit_counter,
                counter_id: this.edit_counter.id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.edit_counter.id = null
                    this.edit_counter.type = null
                    this.edit_counter.title = null
                    this.edit_counter.value = null
                    this.edit_counter.suffix = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit')
            })
        },
        deleteGallery(id) {
            this.delete_value = id
        },
        handleDeleteOk() {
            this.$store.dispatch("counter/deleteCounter", {
                delete: this.delete_value
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
