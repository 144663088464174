<template>
<div>
    <Breadcrumbs main="Admin" submain="Create" title="Team Member" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <input class="form-control" type="text" placeholder="Enter member name" v-model="details.name">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Nickname</label>
                                        <input class="form-control" type="text" placeholder="Enter member nickname" v-model="details.nickname">
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label>Serial</label>
                                        <input class="form-control" type="number" min="1" placeholder="Enter member serial" v-model="details.serial">
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label>Member Type</label>
                                        <select class="form-control digits" v-model="details.type">
                                            <option v-for="(type, index) in type_list" :key="index.id" :value="type.value">{{ type.name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label>Designation</label>
                                        <input class="form-control" type="text" placeholder="Enter member designation" v-model="details.designation">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Image (250 X 250) PX</label>
                                        <div class="flex items-center">
                                            <img v-if="show_image" style="width: 100px; height: 100px;" :src="show_image">
                                            <input :class="details.image ? 'ml-4' : 'ml-0'" type="file" accept="image/*" @change="uploadFile">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Short Description</label>
                                        <textarea class="form-control" id="textarea-auto-height" placeholder="Enter short description..." rows="5" max-rows="8" v-model="details.short_desc"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Facebook</label>
                                        <input class="form-control" type="text" placeholder="Enter Facebook URL" v-model="details.link.fb">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Instagram</label>
                                        <input class="form-control" type="text" placeholder="Enter Instagram URL" v-model="details.link.instagram">
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Twitter</label>
                                        <input class="form-control" type="text" placeholder="Enter Twitter URL" v-model="details.link.twitter">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Portfolio</label>
                                        <input class="form-control" type="text" placeholder="Enter Portfolio URL" v-model="details.link.portfolio">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button @click="onSubmit" class="btn btn-success mr-3" :disabled="isDisabled">Create</button>
                                        <router-link to="/admin/team">
                                            <button class="btn btn-danger" href="#">Go Back</button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </px-card>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from "moment";
import Multiselect from 'vue-multiselect';
import 'sweetalert2/src/sweetalert2.scss';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import treeVue from '../../advance/tree.vue';

export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        Multiselect
    },

    data() {
        return {
            isDisabled: false,
            editor: ClassicEditor,
            details: {
                name: null,
                nickname: null,
                serial: null,
                type: null,
                designation: null,
                short_desc: null,
                link: {
                    fb: '',
                    instagram: '',
                    twitter: '',
                    portfolio: '',
                },
                image: null,
            },
            type_list: [{
                    name: 'Local',
                    value: 'LOCAL'
                },
                {
                    name: 'Global',
                    value: 'GLOBAL'
                }
            ],
            show_image: null,

        };
    },

    computed: {
        ...mapState({

        }),

    },

    mounted() {

    },

    methods: {

        uploadFile(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.show_image = e.target.result
                this.details.image = e.target.result;
            };
        },

        onSubmit() {
            this.isDisabled = true

            this.$store.dispatch("team/addTeam", {
                team: this.details,
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.isDisabled = false,
                        this.details.name = null,
                        this.details.nickname = null,
                        this.details.serial = null,
                        this.details.type = null,
                        this.details.designation = null,
                        this.details.short_desc = null,
                        this.details.link.fb = '',
                        this.details.link.instagram = '',
                        this.details.link.twitter = '',
                        this.details.link.portfolio = '',
                        this.details.image = null
                    setTimeout(() => {
                        this.$router.push('/admin/team')
                    }, 2000)

                } else {
                    this.isDisabled = false
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }

            })
        }
    }
};
</script>
