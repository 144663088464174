
let final_list={
    "data":[
        // {
        //     "path": "/dashboard",
        //     "title": "Dashboard",
        //     "icon": "home",
        //     "type": "link",
        //     "active": false
        // },
        {
            "headTitle1": "Admin",
            // "headTitle2": "Manage Your Daily Dealings",
            "type": "headtitle"
        },
        {
            "path": "/admin/slider",
            "title": "Slider",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/application",
            "title": "Application",
            "icon": "disc",
            "type": "link"
        },

        {
            "path": "/admin/counter",
            "title": "Counter",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/gallery",
            "title": "Gallery",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/content",
            "title": "Content",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/base-detail/who_we_are",
            "title": "Who We Are",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/partner",
            "title": "Partners",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/team",
            "title": "Team Member",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/annual-reports",
            "title": "Annual Reports",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/what-we-do",
            "title": "What We Do",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/campaign",
            "title": "Campaigns",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/law-policy",
            "title": "Law & Policy",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/news",
            "title": "News",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/press-release",
            "title": "Press Release",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/quotes",
            "title": "Quotes",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/contact",
            "title": "Contact",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/qna",
            "title": "QnA",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/subscribers",
            "title": "Subscribers",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/site-setting",
            "title": "Site Settings",
            "icon": "disc",
            "type": "link"
        },


       


        

      
       

      

        
       
       

       
       
       
      
       
     
      
       
    ]
}






export default final_list
