<template>
    <div>
        <Breadcrumbs main="Admin" title="Subscribers" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header header-mobile">
                  
                        </div>
    
                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">
                                    <div class="d-flex flex-column flex-xl-row " style="gap: 10px;">
                                        <b-input-group class="datatable-btn">
                                            <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                            <b-input-group-append>
                                                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                            </b-input-group-append>
                                        </b-input-group>
    
                                    </div>
    
                                </b-col>
    
                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
    
                            <div v-if="!loading && !error">
                                <div class="table-responsive datatable-vue">
                                    <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                        <template #cell(index)="data">
                                            {{ (currentPage - 1) * perPage + data.index + 1 }}
                                        </template>
                                        <template #cell(email)="data">
                                            <div v-if="data.item.email">
                                                {{ data.item.email }}
                                            </div>
                                        </template>
                                        <template #cell(active)="data">
                                            <span v-if="data.item.active === true">
                                                <b-badge pill variant="success">Yes</b-badge>
                                            </span>
                                            <span v-if="data.item.active === false">
                                                <b-badge pill variant="danger">No</b-badge>
                                            </span>
                                        </template>
                                       
                                       
                                    </b-table>
                                </div>
                            </div>
    
                            <div v-else>
                                <loader />
                            </div>
    
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                            </b-col>
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    
        <!-- Delete Modal -->
        <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
            <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this news?</div>
        </b-modal>
    
    </div>
    </template>
    
    <script>
    import {
        mapState
    } from 'vuex';
    import Swal from 'sweetalert2/dist/sweetalert2.js';
    import 'sweetalert2/src/sweetalert2.scss';
    import js from '../../../auth/js';
    import {
        myHost
    } from '../../../_helpers/host'
    import loader from '../loader/loader.vue'
    export default {
        components: {
            loader,
        },
        data() {
            return {
                host: myHost.host,
                tablefields: [{
                        key: 'index',
                        label: '#'
                    },
                    {
                        key: 'email',
                        label: 'Email',
                        sortable: true,
    
                    },
                    {
                        key: 'active',
                        label: 'Active Status',
                        sortable: true,
    
                    },
                   
                ],
    
                filter: null,
    
    
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [5, 10, 15]
            };
        },
    
        computed: {
            ...mapState({
                items: state => state.contact.subscribers,
                loading: state => state.contact.subscribersLoading,
                error: state => state.contact.subscribersError,
            }),
    
            sortOptions() {
                return this.tablefields
                    .filter(f => f.sortable)
                    .map(f => {
                        return {
                            text: f.label,
                            value: f.key
                        };
                    });
            }
        },
    
        mounted() {
    
            this.$store.dispatch("contact/fetchSubscribers").then(() => {
                this.totalRows = this.items;
    
            });
    
        },
    
        watch: {
    
        },
    
        methods: {
    
            truncateText(text, maxLength) {
                if (text.length > maxLength) {
                    return text.substring(0, maxLength) + '...';
                } else {
                    return text;
                }
            },
    
            resetModal() {
                this.selectedStatus = ''
            },
    
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
    
          
    
        }
    };
    </script>
    
    <style scoped>
    .header-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    @media screen and (max-width: 460px) {
        .header-mobile {
            display: flex;
            flex-direction: column;
            gap: 16px;
            align-items: start;
        }
    }
    </style>
    