import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'WHAT_WE_DO_UPDATE',
    smsDelete : 'WHAT_WE_DO_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {
        
        what_we_do:[],
      
        what_we_do_detail:{},

        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        what_we_do_Loading: false,
        what_we_do_Error: false,

        
       
        ...store
    },

    mutations : {
        ...mutations,

        SET_WHAT_WE_DO(state, law) {
            state.what_we_do = law;
        },

        SET_WHAT_WE_DO_DETAIL(state, detail) {
            state.what_we_do_detail = detail;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },
        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },


        WHAT_WE_DO_LOADING (state, loading) {
            state.what_we_do_Loading = loading;
        },

        WHAT_WE_DO_ERROR (state, hasError) {
            state.what_we_do_Error = hasError;
        },
        


       
    },

    actions: {


        async whatDoCustomToggle ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.get(V1API.what_we_do_custom_toggle + payload.what_we_do_slug, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchWhatDo');
            
            return response
        },

    



        //What We Do
        async addWhatDO({commit, dispatch}, payload) {
            commit('WHAT_WE_DO_LOADING', true);
            commit('WHAT_WE_DO_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_what_we_do + payload.type, payload.what_we_do, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                // dispatch ('fetchWhatDo')
               
                return response

            }catch (e) {
                commit('WHAT_WE_DO_ERROR', true);
            }finally {
                commit('WHAT_WE_DO_LOADING', false);
            }
        },

        async fetchWhatDoDetail({ commit },payload) {
            commit('WHAT_WE_DO_LOADING', true);
            commit('WHAT_WE_DO_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_what_we_do_detail + payload, config);
                let response = result.data.data

                commit('SET_WHAT_WE_DO_DETAIL', response);

            } catch(error) {
                commit('WHAT_WE_DO_ERROR', true);
            }
            finally {
                commit('WHAT_WE_DO_LOADING', false);
            }
        },



        async fetchWhatDo ({ commit }) {
            commit('WHAT_WE_DO_LOADING', true);
            commit('WHAT_WE_DO_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_what_we_do, config);
                let response = result.data

                commit('SET_WHAT_WE_DO', response.data);

            } catch(error) {
                commit('WHAT_WE_DO_ERROR', true);
            }
            finally {
                commit('WHAT_WE_DO_LOADING', false);
            }
        },



        
        async deleteWhatDo({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.what_we_do + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchWhatDo')
            
            return response
        },

    },

    getters: {}
}
