import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'GALLERY_UPDATE',
    smsDelete : 'GALLERY_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {
        
        team:[],
        team_detail:{},
       
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        teamLoading: false,
        teamError: false,

        
       
        ...store
    },

    mutations : {
        ...mutations,

        SET_TEAM(state, law) {
            state.team = law;
        },
        SET_TEAM_DETAIL(state, detail) {
            state.team_detail = detail;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },
        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },


        TEAM_LOADING (state, loading) {
            state.teamLoading = loading;
        },

        TEAM_ERROR (state, hasError) {
            state.teamError = hasError;
        },
        


       
    },

    actions: {
        //Team Member
        async addTeam ({commit, dispatch}, payload) {
            commit('TEAM_LOADING', true);
            commit('TEAM_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_team, payload.team, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
               
               
                return response

            }catch (e) {
                commit('TEAM_ERROR', true);
            }finally {
                commit('TEAM_LOADING', false);
            }
        },

        async fetchTeamDetail ({ commit },payload) {
            commit('TEAM_LOADING', true);
            commit('TEAM_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_team_details + payload, config);
                let response = result.data.data

                commit('SET_TEAM_DETAIL', response);

            } catch(error) {
                commit('TEAM_ERROR', true);
            }
            finally {
                commit('TEAM_LOADING', false);
            }
        },

       


        async fetchTeamMember ({ commit }) {
            commit('TEAM_LOADING', true);
            commit('TEAM_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.team_list, config);
                let response = result.data

                commit('SET_TEAM', response.data);

            } catch(error) {
                commit('TEAM_ERROR', true);
            }
            finally {
                commit('TEAM_LOADING', false);
            }
        },


    
        async updateTeam ({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.patch(V1API.update_team+payload.id, payload.team, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
              
                return response

            }catch (e) {
                commit('TEAM_ERROR', true);
            }finally {
                commit('TEAM_LOADING', false);
            }
        },

        
        async deleteTeam({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            console.log(payload.delete)
            let response = await axios.delete(V1API.team_delete+payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchTeamMember')
            
            return response
        },


        











       








    },

    getters: {}
}
