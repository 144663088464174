<template>
<div id="app">
    <!-- Loader starts-->
    <div class="loader-wrapper" :class="{'loderhide' : !show }">
        <div class="d-flex flex-column">
            <img src="./assets/images/logo/logo.svg" alt="">
            <div class="loader-box">
                <div class="loader-17"></div>
            </div>
        </div>
    </div>
    <!-- Loader ends-->

    <!-- Modal -->

    <!-- isPolicy_Compliance Submitted -->
    <!-- <b-modal id="modal-show" centered hide-footer>
        <div class="d-flex flex-column justify-content-center align-items-center">
            <h7 style="font-size: large;" id="responseMsg">You've not submitted the Policy & Compliance form!</h7>
            <div class="mt-4">
                <router-link to="/user/profile"> <button class="btn btn-danger">Submit Now</button></router-link>
            </div>
        </div>
    </b-modal> -->

    <!--<Main/>-->
    <router-view></router-view>
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
export default {
    name: 'app',
    data() {
        return {
            show: true,
            showsubmitModal: true,
        };
    },
    mounted() {
        this.timeOut();

        // const isLoggedIn = localStorage.getItem("user");
        // if (isLoggedIn) {
        //     this.$store.dispatch("onBoarding/fetchonBoardRemains").then(() => {

        //         if (this.policySubmit == false || this.complianceSubmit == false) {

        //             this.$nextTick(() => {
        //                 this.$bvModal.show('modal-show');
        //             })

        //             setTimeout(() => {
        //                 this.$nextTick(() => {
        //                     this.$bvModal.hide('modal-show');
        //                 })
        //             }, 5000)
        //         }
        //     });
        // }

    },
    computed: {
        ...mapState({
            // policySubmit: state => state.onBoarding.policy_reamins,
            // complianceSubmit: state => state.onBoarding.compliance_remains,

        }),

    },
    methods: {
        timeOut() {
            var self = this;
            setTimeout(function () {
                self.show = false;
            }, 1000);
        },

    }
};
</script>

<style>
.loader-wrapper.loderhide {
    display: none;
}

.ck-editor__editable {
    min-height: 200px;
}

.link-style {
    /* font-size: 14px; */
    font-weight: 600;
}

.link-style:hover {
    color: #ff595d;

}


</style>
